import { Point } from "geojson";
import { PaginatedResultSet, DetailPageCorrection } from ".";

export enum SampleListItemType {
    Tissue = "Tissue",
    Voucher = "Voucher",
    IncidentalObservation = "Incidental Observation",
}

export enum TissueSampleType {
    TISSUE = "Tissue",
    DESTRUCTIVE = "Destructive",
}

export enum TrueOrFalseType {
    TRUE = "True",
    FALSE = "False",
}

export enum SourceOfCoordinates {
    IFORMS = "iForms",
    GPS = "GPS",
    MANUAL = "Manual",
    INFERRED = "Inferred",
    DATA_SUPPLIER = "Data supplier",
    // NOTE: these are all extra. conform to above?
    GOOGLE_EARTH = "Google Earth",
    DATA_SOURCE = "Data source",
    EMU = "EMu",
    HERBARIUM = "Herbarium data",
}

export enum ObservationType {
    SIGHTED = "Sighted",
    OBSERVATION = "Observation",
    NOT_SIGHTED = "Not Sighted",
}

// Better viewed as phenology field, for now leaving it as is
export enum Flowers {
    BUDS = "Immature/Buds",
    MATURE = "Mature/Flower",
    MALE = "Male",
    FEMALE = "Female",
    BISEXUAL = "Bisexual",
    NOT_SURE_FLOWERS = "Not sure (reproductive structures present)",
    NOT_PRESENT = "Not present",
    BUDS_LEGACY = "Buds",
    MATURE_LEGACY = "Mature",
    NOT_SURE_FLOWERS_LEGACY = "Not sure (flowers present)",
    NOT_SURE_LEGACY = "Not sure",
}

export enum ReproductiveState {
    FERTILE = "Fertile",
    STERILE = "Sterile",
}

export enum Fruit {
    IMMATURE = "Immature",
    MATURE = "Mature",
    SEED_RELEASED = "Seed released",
    NOT_PRESENT = "Not present",
    NOT_SURE = "Not sure (fruit present)",
    SEED_MATURE_LEGACY = "Seed mature",
}

export enum SpeciesIDConfidence {
    CONFIDENT = "Confident",
    UNCERTAIN = "Uncertain",
}

export enum NativeStatus {
    NATIVE_WILD = "Native - wild",
    NATIVE_RESTORED = "Native - restored",
    NATURALISED = "Naturalised",
    INTRODUCED = "Introduced",
    CULTIVATED = "Cultivated",
}

export enum HybridStatus {
    KNOWN_HYBRID = "Known hybrid",
    PUTATIVE_HYBRID = "Putative hybrid",
    NON_HYBRID = "Non-hybrid",
}

// ----

export type SamplesListItem = {
    /**
     * ID is either the NSW number or observation ID, dependent on type.
     */
    id: string;
    NSWNumber?: string;
    observationId?: string;
    type: SampleListItemType;
    tissueSampleType?: TissueSampleType;
    sampleLocation: Point;
    acceptedName: string | null;
    fieldSpeciesName: string | null;
    peoplePresent: string | null;
    locality: string;
    sampleDate: string | null;
    projects: string | null;
} & SamplesListItemCounts;

export type SamplesListItemCounts = {
    dartOrderCount: number | null;
};

export type SamplesListResponse = PaginatedResultSet<SamplesListItem>;

export type SampleDetailPopulationInfo = {
    readonly plants10m: string | null;
    readonly adultsPresent: string | null;
    readonly juvenilesPresent: string | null;
    readonly speciesIDConfidence: SpeciesIDConfidence | null;
    readonly speciesIDNotes: string | null;
    readonly nativeStatus: NativeStatus | null;
    readonly notes: string | null;
};

export type SampleDetailPhenology = {
    readonly reproductiveState: ReproductiveState | null;
    /**
     * Comma separated
     */
    readonly flowers: string | null;
    /**
     * Comma separated
     */
    readonly fruit: string | null;
};

export type ObservationDetailBase = {
    eventKey: string | null;
    readonly peoplePresent: string | null;
    readonly locality: string | null;
    readonly fieldSpeciesName: string | null;
    readonly fieldNumber: string | null;
    readonly siteNotes: string | null;
    sampleLocation: Point;
    altitude: number | null;
    sourceOfCoordinates: SourceOfCoordinates | null;
    generalNotes: string | null;
    corrections: DetailPageCorrection[] | [];
    associatedProjects: string | null;
    infraSpecificName: string | null;
    infraSpecificRank: string | null;

    /**
     * Taken from the most current identification.
     */
    acceptedName: string | null;
    taxonKey: string | null;
    dateIdentified: string | null;
    identifiedBy: string | null;
    identificationRemarks: string | null;

    readonly populationInfo: SampleDetailPopulationInfo;
    readonly phenology: SampleDetailPhenology;
    hybridStatus: HybridStatus | null;
    hybridStatusNotes: string | null;

    archived: boolean;
};

export type SampleDetailBase = ObservationDetailBase & {
    readonly sampleDate: string | null;
    boxNumber: string | null;
    bundleNumber: string | null;
    collectionNotes: string;
};

export type TissueSampleDetail = SampleDetailBase & {
    readonly NSWnumber: string;
    readonly type: SampleListItemType.Tissue;
    readonly dartOrderCounts: Record<string, number>;
    tissueSampleType: TissueSampleType;
    readonly linkedVoucherSample: string | null;

    // -----
    // Herbarium / Destructive stuff
    // TODO: fancy type union if sample type destructive?
    herbariumName: string | null;
    herbariumSpecimenIRN: string | null;
    herbariumSpecimenCollector: string | null;
    herbariumCollectionDate: string | null;
    destructiveSamplingDate: string | null;
    destructiveSamplingBy: string | null;
    destructiveSamplingSiteName: string | null;
};

export type VoucherSampleDetail = SampleDetailBase & {
    readonly NSWnumber: string;
    readonly type: SampleListItemType.Voucher;
    readonly linkedTissueSample: string | null;
};

export type IncidentalObservationDetail = ObservationDetailBase & {
    readonly observationId: string;
    readonly type: SampleListItemType.IncidentalObservation;
    readonly observationType: ObservationType;
    readonly observationDate: string | null;
};

// ------

export type CommonSamplePost = {
    NSWnumber: string;
    eventKey: string;
    sampleDate: string;
    fieldSpeciesName: string;
    taxonKey: string;
};

export type IncidentalObservationPost = {
    observationId: string;
    eventKey: string;
    observationDate: string;
    fieldSpeciesName: string;
    observationType: ObservationType;
    taxonKey: string;
};

// ------

export type IdentificationUpdate = {
    taxonKey?: string;
    dateIdentified?: string;
    identificationRemarks?: string;
    identifiedBy?: string;
};

type ObservationBasePatch = {
    sampleLocation?: Point;
    sourceOfCoordinates?: SourceOfCoordinates | null;
    eventKey?: string | null;
    generalNotes?: string | null;
    hybridStatus?: HybridStatus | null;
    hybridStatusNotes?: string | null;
};

type CommonSamplePatch = ObservationBasePatch & {
    boxNumber?: string | null;
    bundleNumber?: string | null;
    collectionNotes: string | null;
};

export type TissueSamplePatch = CommonSamplePatch & {
    identification?: IdentificationUpdate;
    tissueSampleType?: TissueSampleType;

    // -----
    // Herbarium / Destructive stuff
    herbariumName?: string | null;
    herbariumSpecimenIRN?: string | null;
    herbariumSpecimenCollector?: string | null;
    herbariumCollectionDate?: string | null;
    destructiveSamplingDate?: string | null;
    destructiveSamplingBy?: string | null;
    destructiveSamplingSiteName?: string | null;
};

export type VoucherSamplePatch = CommonSamplePatch & {
    identification?: IdentificationUpdate;
};

type IncidentalObservationCorrection = ObservationBasePatch & {};

export type IncidentalObservationPatch = IncidentalObservationCorrection & {
    identification?: IdentificationUpdate;
};

// ----
// This is related to destructive samples

export type HerbariumAutocompleteRow = {
    herbariumID: string;
    institutionName: string;
    collectionCode: string | null;
};
