import React from "react";
import { Check } from "@material-ui/icons";
import { DartOrderDetailSample } from "../../types";
import { useTheme } from "@material-ui/core/styles";

type WellPlateCircleProps = {
    isUnusedCell: boolean;
    itemAtIndex: DartOrderDetailSample | undefined;
    wellColor: string;
    onClick: () => void;
};

function WellPlateCircle({ isUnusedCell, itemAtIndex, onClick, wellColor }: WellPlateCircleProps) {
    const theme = useTheme();

    return (
        <div
            className="sample-circle"
            onClick={onClick}
            style={{
                backgroundColor: itemAtIndex?.inPlate
                    ? wellColor
                    : isUnusedCell
                    ? theme.palette.neutral.dark
                    : "white",
                border: itemAtIndex
                    ? itemAtIndex.inPlate
                        ? "none"
                        : `4px solid ${wellColor}`
                    : `1px solid ${wellColor}`,
                cursor: isUnusedCell ? "not-allowed" : "pointer",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                display: "grid",
                alignItems: "center",
                justifyItems: "center",
            }}
        >
            {itemAtIndex?.inPlate && (
                <Check
                    style={{
                        color: theme.palette.neutral.light,
                        width: "auto",
                        height: "auto",
                    }}
                />
            )}
        </div>
    );
}

export default WellPlateCircle;
