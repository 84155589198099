import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { markerColorsMap } from "../taxon-map/taxon-map";
import RoomIcon from "@material-ui/icons/Room";
import { MapItemType } from "../../types";
import { mapItemTypes } from "../../modules/listItemsFromEnums";
import { strings } from "../../modules/strings/strings";
const { buttons } = strings;

type MapFilterProps = {
    handleMapTypeChange: (types: MapItemType[]) => void;
    activeType: string | undefined;
};

// Checkboxes to filter sample types on map:
// "Tissue (Sent to DArT)", "Tissue (No DaRT)", "Voucher", "Incidental Observation"
function MapCheckboxes({ handleMapTypeChange, activeType }: MapFilterProps) {
    // Turn active type from URL (which may be a comma separated string) into an array
    const activeTypes = activeType?.split(",").map(t => t.trim() as MapItemType) || [];

    const theme = useTheme();

    return (
        <>
            <div>
                {mapItemTypes.map(type => {
                    return (
                        <div key={type}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox
                                    checked={activeTypes.includes(type)}
                                    style={{ color: theme.palette.info.main }}
                                    onChange={() => {
                                        if (activeTypes.includes(type)) {
                                            const newActiveTypes = activeTypes.filter(
                                                t => t !== type
                                            );
                                            handleMapTypeChange(newActiveTypes);
                                        } else {
                                            const newActiveTypes = [type, ...activeTypes];
                                            handleMapTypeChange(newActiveTypes);
                                        }
                                    }}
                                />
                                <RoomIcon style={{ color: markerColorsMap[type] }} />
                                <span>{buttons.labels[type]}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default MapCheckboxes;
