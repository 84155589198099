import React, { useState, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import Toast from "../../components/toast/toast";
import { DartOrderPatch, DartOrderDetail } from "../../types";
import { useDetailForm } from "../../hooks/useDetailForm";
import DetailPage from "../../components/detail-page/detail-page";
import WellPlateForm from "../../components/well-plate/well-plate-form";
import { orderForm } from "./orders-detail-page-config";
import ActionButton from "../../components/button/action-button";
import { api } from "../../modules/api";
import { history } from "../../pages/root";
import { debounce } from "lodash";

function OrderDetailPage() {
    let { id } = useParams<{ id: string }>();
    const [toastMessage, setToastMessage] = useState<string | null>(null);

    const {
        displayData,
        patchData,
        setPatchValue,
        undoUnsavedChanges,
        submit,
        errors,
        savingStatus,
    } = useDetailForm<DartOrderDetail, DartOrderPatch>(id, "dart-order", "dart-order", orderForm);

    const debouncedSubmit = useMemo(
        () =>
            debounce((id: string, patch: DartOrderPatch) => {
                api(
                    `dart-order/${id}/`,
                    {
                        method: "PATCH",
                        body: JSON.stringify(patch),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                    true
                );
            }, 2000),
        []
    );

    // Automatically save the well plate patch as it's being edited
    const setAndSaveWellPlatePatch = useCallback(
        (key: string, value: any, id: string) => {
            const patchUpdate = setPatchValue(key, value, id);
            // Only submit samples and species
            const { samples, species } = patchUpdate;
            debouncedSubmit(id, { samples, species });
        },
        [setPatchValue, debouncedSubmit]
    );

    const handleDelete = () => {
        if (
            window.confirm(
                `This is an irreversible operation! Are you sure you want to delete DArT order "${id}"?`
            )
        ) {
            api(`dart-order/${id}`, { method: "DELETE" }, true).then(response => {
                if (response.ok) {
                    history.push({ pathname: `/dashboard/orders` });
                } else {
                    setToastMessage("Failed to delete order");
                    console.error("Error deleting order:", response);
                }
            });
        }
    };

    return (
        <div>
            {displayData && (
                <DetailPage
                    id={id}
                    header={"DArT Order: " + displayData.serviceNumber || ""}
                    formSections={orderForm}
                    displayData={displayData}
                    patchData={patchData}
                    submit={submit}
                    setPatchValue={setPatchValue}
                    undoUnsavedChanges={undoUnsavedChanges}
                    errors={errors}
                    backToPage={"order details"}
                    detailPagePath={"orders"}
                    savingStatus={savingStatus}
                />
            )}
            <WellPlateForm
                id={id}
                displayData={displayData}
                setPatchValue={setAndSaveWellPlatePatch}
            />
            <div style={{ marginTop: 80 }}>
                <ActionButton label="Delete Order" severity="danger" onClick={handleDelete} />
            </div>
            {toastMessage && <Toast message={toastMessage} error={true} />}
        </div>
    );
}

export default OrderDetailPage;
