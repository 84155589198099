import React from "react";
import { DetailKey, DetailPageSection, DataError } from "../../types";
import DetailFormField from "./detail-form-field";
import { AppPage } from "../../ducks/ui";
import { SetPatchFunction } from "../../hooks/useDetailForm";

export type UpdateOptions = {
    appendString?: boolean;
    useIndex?: number;
};

type DetailFormProps = {
    id: string; // id of entity, e.g. NSWnumber
    onUpdateField: SetPatchFunction;
    formFields: DetailPageSection[];
    errors: Map<DetailKey, DataError>;
    detailObject: any;
    backToPage: AppPage;
};

function DetailForm({
    onUpdateField,
    formFields,
    detailObject,
    backToPage,
    errors,
    id,
}: DetailFormProps) {
    return (
        <div>
            {detailObject &&
                formFields.map((section, i) => {
                    if (section.showCondition) {
                        if (!section.showCondition(detailObject)) {
                            return null;
                        }
                    }
                    return (
                        <div key={`${section.sectionTitle} ${i}`}>
                            <div style={{ marginLeft: 10, marginTop: 30, maxWidth: 750 }}>
                                {section.sectionTitle && <h3>{section.sectionTitle}</h3>}
                                {section.sectionNotes && <p>{section.sectionNotes}</p>}
                            </div>

                            {section.fields.map((field, i) => {
                                if (field.showCondition) {
                                    if (!field.showCondition(detailObject)) {
                                        return null;
                                    }
                                }
                                if (field.subFields) {
                                    return field.subFields.map((subField, i) => {
                                        return (
                                            <DetailFormField
                                                id={id}
                                                field={subField}
                                                onUpdateField={onUpdateField}
                                                detailObject={detailObject[field.key]}
                                                parentKey={field.key}
                                                key={i}
                                                backToPage={backToPage}
                                            />
                                        );
                                    });
                                } else {
                                    return (
                                        <DetailFormField
                                            id={id}
                                            field={field}
                                            onUpdateField={onUpdateField}
                                            detailObject={detailObject}
                                            key={i}
                                            backToPage={backToPage}
                                            validationCheck={errors.get(field.key)}
                                        />
                                    );
                                }
                            })}
                        </div>
                    );
                })}
        </div>
    );
}

export default DetailForm;
