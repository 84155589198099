import React from "react";
import { DataError } from "../../types";
import { useTheme } from "@material-ui/core/styles";
import { EditIcon } from "../icons";
import ErrorIcon from "@material-ui/icons/Error";

type DetailFormFieldWrapperProps = {
    body: JSX.Element;
    isReadOnly: boolean;
    error?: DataError | null;
    fullWidth?: boolean;
};

function DetailFormFieldWrapper({
    body,
    isReadOnly,
    error,
    fullWidth,
}: DetailFormFieldWrapperProps) {
    const theme = useTheme();
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: 4,
                maxWidth: fullWidth ? "unset" : 990,
                border: error?.message ? `1px solid ${theme.palette.error.main}` : "none",
                padding: error?.error ? 10 : "5px 10px",
                margin: "10px 0px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    maxWidth: fullWidth ? "unset" : 750,
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {body}
                {!isReadOnly && (
                    <div>
                        <EditIcon
                            style={{
                                color: theme.palette.icon.main,
                                marginLeft: 20,
                            }}
                        />
                    </div>
                )}
            </div>
            <div>
                {error?.error && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <ErrorIcon
                            style={{ color: theme.palette.error.main, margin: "0px 10px" }}
                        />
                        <span>{error.message}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

export default DetailFormFieldWrapper;
