import { NewItemModalField } from "../../types";
import {
    checkValidId,
    ensureUniqId,
    checkNameIsUnique,
} from "../../modules/validation/checkValidId";
import { api } from "../../modules/api";
import { parseEventAutocompleteResponse } from "../../modules/detail-page-helpers/detail-page-helpers";

const fields: { [key: string]: NewItemModalField } = {
    // Common sample fields
    NSWnumber: {
        label: "NSW Number",
        key: "NSWnumber",
        isReadOnly: false,
        inputType: "textfield",
        validate: id => ensureUniqId("samples", id),
    },
    observationId: {
        label: "Observation ID",
        key: "observationId",
        isReadOnly: false,
        inputType: "textfield",
        validate: id => ensureUniqId("samples", id),
    },
    eventKey: {
        label: "Event Key",
        key: "eventKey",
        isReadOnly: false,
        inputType: "textfield",
        validate: id => checkValidId("sampling-event", id),
    },
    eventKeyAutocomplete: {
        label: "Event Key",
        key: "eventKey",
        isReadOnly: false,
        inputType: "autocomplete-async",
        getOptions: async query => {
            if (query.length < 2) return [];

            const response = await api(`sampling-event/autocomplete/${query}`);
            const responseJson = await response.json();
            return parseEventAutocompleteResponse(responseJson);
        },
    },
    sampleDate: {
        label: "Sample Date",
        key: "sampleDate",
        isReadOnly: false,
        inputType: "datepicker",
    },
    fieldSpeciesName: {
        label: "Field Species Name",
        key: "taxonKey",
        isReadOnly: false,
        inputType: "autocomplete",
        optionsKey: "speciesWithGenera",
    },

    // Observation fields
    observationDate: {
        label: "Observation Date",
        key: "observationDate",
        isReadOnly: false,
        inputType: "datepicker",
    },
    observationType: {
        label: "Observation Type",
        key: "observationType",
        isReadOnly: true,
        inputType: "textfield",
    },

    // Event fields
    eventKeyForEvent: {
        label: "Event key",
        key: "eventKey",
        isReadOnly: false,
        inputType: "textfield",
        validate: id => ensureUniqId("sampling-event", id),
    },
    eventID: {
        label: "Event ID",
        key: "eventID",
        isReadOnly: false,
        inputType: "textfield",
        validate: id => ensureUniqId("sampling-event", id),
    },
    eventDate: {
        label: "Event date",
        key: "eventDate",
        isReadOnly: false,
        inputType: "datepicker",
    },
    eventSource: {
        label: "Event source",
        key: "eventSource",
        isReadOnly: true,
        inputType: "textfield",
    },

    // Order fields
    serviceNumber: {
        label: "Service number",
        key: "serviceNumber",
        isReadOnly: false,
        inputType: "textfield",
        validate: id => ensureUniqId("dart-order", id),
    },

    // Import and repair
    importFiles: {
        label: "Upload files",
        key: "importFiles",
        isReadOnly: false,
        inputType: "upload",
    },

    // Taxon fields
    acceptedName: {
        label: "Accepted name",
        key: "acceptedName",
        isReadOnly: false,
        inputType: "textfield",
        validate: acceptedName => checkNameIsUnique("taxon-name-is-unique", acceptedName, ""),
    },
    notes: {
        label: "Notes",
        key: "notes",
        isReadOnly: false,
        inputType: "textfield",
    },
};

const {
    NSWnumber,
    eventKey,
    eventKeyAutocomplete,
    observationId,
    sampleDate,
    fieldSpeciesName,
    observationDate,
    observationType,
    eventKeyForEvent,
    eventID,
    eventDate,
    eventSource,
    serviceNumber,
    importFiles,
    acceptedName,
    notes,
} = fields;

// NEW TISSUE & VOUCHER SAMPLES
// /entity/tissue-sample
// {
//     "NSWnumber": "TEST1",
//     "eventKey": "S85_2015-11-25"
//     "sampleDate": "2020-06-24T05:27:30.687Z",
//     "fieldSpeciesName": "Dichondra repens"
// }

export const newCommonSampleFields: NewItemModalField[] = [
    NSWnumber,
    eventKeyAutocomplete,
    sampleDate,
    fieldSpeciesName,
];

// NEW OBSERVATION SAMPLES
// {
//     "observationId": "TEST",
//     "eventKey": "S85_2015-11-25",
//     "observationDate": "2020-06-24T05:27:30.687Z",
//     "fieldSpeciesName": "Tasmannia insipida",
//     "observationType": "Sighted" ----------------> Do we need this?
// }
export const newIncidentalObservationFields: NewItemModalField[] = [
    observationId,
    eventKeyAutocomplete,
    observationDate,
    fieldSpeciesName,
    observationType,
];

// NEW EVENT
// {
//     "eventKey": "TEST_24-06-2020", ----> we can generate this ourselves
//     "eventID": "TEST", ----> we can generate this ourselves
//     "eventDate": "2020-06-24T01:14:06.592Z",
//     "eventSource": "Manual" ----> we will send "manual"
// }
export const newEventFields: NewItemModalField[] = [
    eventKeyForEvent,
    eventID,
    eventDate,
    eventSource,
];

// New order
// {
// 	"serviceNumber": "test"
// }
export const newOrderFields: NewItemModalField[] = [serviceNumber];

export const newImportFields: NewItemModalField[] = [importFiles];

export const newTaxonFields: NewItemModalField[] = [acceptedName, notes];
