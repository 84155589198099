import { ColumnKey } from "../../components/info-table/info-table";

const sortableSampleColumns: ColumnKey[] = [
    "locality",
    "id",
    "acceptedName",
    "type",
    "peoplePresent",
    "dartOrderCount",
    "sampleDate",
    "fieldSpeciesName",
    "tissueSampleType",
];

const sortableEventsColumns: ColumnKey[] = [
    "eventKey",
    "peoplePresent",
    "locality",
    "eventDate",
    "tissueSampleCount",
    "voucherSampleCount",
    "speciesCount",
];

const sortableSpeciesColumns: ColumnKey[] = [
    "taxonKey",
    "acceptedName",
    "type",
    "provisionalAcceptedStatus",
    "priority",
    "samplingStatus",
    "tissueSampleCount",
    "voucherSampleCount",
    "dartOrderCount",
];

const sortableOrderColumns: ColumnKey[] = [
    "serviceNumber",
    "dateSent",
    "service",
    "dateReceived",
    "orderSampleCount",
    "finalOrderCount",
];

export const sortableColumns = {
    samples: sortableSampleColumns,
    orders: sortableOrderColumns,
    events: sortableEventsColumns,
    species: sortableSpeciesColumns,
};
