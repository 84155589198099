import React from "react";
import { TextField } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { FilterOptionsFromState, DataError, NewItemModalField } from "../../types";
import { selectSpeciesWithGenera, selectProjectData } from "../../ducks/constant-data";
import { useSelector } from "../../ducks/root-reducer";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import FilterDropdown from "../../components/filter-dropdown/filter-dropdown";
import AutocompleteSelect from "../../components/detail-page-autocomplete/autocomplete-select";
import AutocompleteSelectAsync from "../detail-page-autocomplete-async/autocomplete-select-async";

type NewItemFormFieldProps = {
    displayData: any;
    field: NewItemModalField;
    setPostValue: (key: string, value: any) => void;
    validationCheck: DataError | undefined;
};

function NewItemFormField({
    displayData,
    field,
    setPostValue,
    validationCheck,
}: NewItemFormFieldProps) {
    const { key, isReadOnly, label, optionsKey, inputType, options } = field;
    const speciesWithGenera = useSelector(selectSpeciesWithGenera);
    const projects = useSelector(selectProjectData);

    const selectStateOptions = (
        optionsKey: FilterOptionsFromState | undefined
    ): FilterListItem[] => {
        switch (optionsKey) {
            case "speciesWithGenera":
                return speciesWithGenera ? speciesWithGenera : [];
            case "projectList":
                return projects ? projects : [];
            default:
                return [];
        }
    };

    const hasError: boolean = validationCheck !== undefined && validationCheck.error;
    const errorMessage: string | undefined = validationCheck && validationCheck.message;

    return (
        <>
            {inputType === "textfield" && (
                <TextField
                    label={label}
                    value={displayData[key]}
                    InputProps={{
                        readOnly: isReadOnly,
                        disableUnderline: isReadOnly,
                    }}
                    onChange={e => {
                        setPostValue(key, e.target.value);
                    }}
                    error={hasError}
                    helperText={errorMessage}
                    style={{
                        display: "block",
                        marginBottom: 20,
                    }}
                    fullWidth
                    multiline
                />
            )}
            {inputType === "datepicker" && (
                <div style={{ marginBottom: 20 }}>
                    <DatePicker
                        label={label}
                        value={displayData[key]}
                        style={{ width: "100%", maxWidth: 700 }}
                        format="DD/MM/YYYY"
                        onChange={newDate => {
                            setPostValue(key, newDate?.toDate() || new Date());
                        }}
                        disabled={isReadOnly}
                    />
                </div>
            )}
            {inputType === "filter" && (
                <FilterDropdown
                    handleChange={e => {
                        setPostValue(key, e.target.value);
                    }}
                    label={label}
                    value={displayData[key]}
                    options={{ removeEmptyOption: true }}
                    menuItems={options || selectStateOptions(optionsKey)}
                />
            )}
            {inputType === "autocomplete" && (
                <AutocompleteSelect
                    handleChange={e => {
                        setPostValue(key, e.target.value);
                    }}
                    menuItems={options || selectStateOptions(optionsKey)}
                    label={label}
                    value={displayData[key]}
                />
            )}
            {inputType === "autocomplete-async" && (
                <AutocompleteSelectAsync
                    handleChange={e => {
                        setPostValue(key, e.target.value);
                    }}
                    getOptions={field.getOptions}
                    label={label}
                    value={displayData[key]}
                />
            )}
            {inputType === "upload" && (
                <input
                    type="file"
                    accept=".txt,.tsv"
                    multiple
                    onChange={e => {
                        const formData = new FormData();
                        const files = e.target.files;
                        if (files) {
                            for (let i = 0; i < files.length; i++) {
                                formData.append("file", files[i], files[i].name);
                            }
                        }

                        setPostValue(key, formData);
                    }}
                />
            )}
        </>
    );
}

export default NewItemFormField;
