import { DartOrderDetailSpecies, DartOrderDetailSample } from "../../types";
import { plateCoordsToIndex } from "../../modules/numberToCoords";
import { parse } from "json2csv";
import { orderBy } from "lodash";

// Rows in the CSV that is sent to DArT
type DartOrderRow = {
    PlateID: number;
    Row: string;
    Column: number;
    Organism: string | undefined;
    Species: string | undefined;
    Genotype: string;
    Tissue: string | undefined;
    Comments: string | undefined;
    index?: number; // Just for sorting
};

// This is doubling up on the work that the table itself does
// But considering we're not dealing with thousands of rows, this is probably fine.
export const samplesToCsvUrl = (
    samples: DartOrderDetailSample[] | undefined,
    species: DartOrderDetailSpecies[] | undefined,
    numRows: number,
    numCols: number
): string => {
    if (!samples || !species || !samples.length) return "";

    // Turn samples into objects that more closely resemble the CSV
    const samplesForCsv: DartOrderRow[] = samples.map(s => {
        const matchingSpecies = species?.find(sample => s.taxonKey === sample.taxonKey);
        return {
            PlateID: s.plateNumber,
            Row: s.row,
            Column: s.column,
            Organism: matchingSpecies?.genus,
            Species: matchingSpecies?.species,
            Genotype: s.displayNswNumber,
            Tissue: undefined,
            Comments: undefined,
            index: plateCoordsToIndex(s.row, s.column, s.plateNumber, numRows, numCols),
        };
    });

    // Sort samples and remove the temporary 'index' field
    const sortedSamples: DartOrderRow[] = orderBy(samplesForCsv, "index", "asc").map(row => {
        const { index, ...rest } = row;
        return rest;
    });

    // Turn object array into CSV and return
    const csv = parse(sortedSamples);
    return csvToObjectUrl(csv);
};

const csvToObjectUrl = (csvString: string): string => {
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
    return URL.createObjectURL(blob);
};
