import React from "react";
import { numberToLetter } from "../../modules/numberToLetter";
import { numberTo3dCoords } from "../../modules/numberToCoords";
import { WellPlates } from "../../hooks/useWellPlate";
import Pagination from "@material-ui/lab/Pagination";
import { DartOrderDetailSample } from "../../types";
import { useWellPlateStyles } from "./well-plate-styles";
import { selectItemAtIndex } from "./well-plate-helpers";
import { PlateOptions } from "./well-plate-config";
import { useTheme } from "@material-ui/core/styles";
import WellPlateCircle from "./well-plate-circle";

const PLATE_HEIGHT: string | number = 220;
const PLATE_WIDTH: string | number = 330;
const LEGEND_WIDTH: string | number = 18;

type WellPlateProps = {
    wellPlates: WellPlates;
    options: PlateOptions;
    samples: DartOrderDetailSample[];
};

function WellPlateGrid({ wellPlates, options, samples }: WellPlateProps) {
    const classes = useWellPlateStyles();
    const theme = useTheme();
    const selectedCell = wellPlates.selectedItem;
    const currentPlate = wellPlates.getCurrentPlateIndex() + 1;
    const numPlates = wellPlates.getNumPlates();
    const { numRows, numCols, unusedIndexes } = options;
    const plateSize: number = numRows * numCols;

    return (
        <div style={{ marginBottom: 30 }}>
            <div
                className="wellplate-with-legends"
                style={{
                    display: "grid",
                    gridTemplateColumns: `${LEGEND_WIDTH}px 1fr`,
                    gridTemplateRows: `${LEGEND_WIDTH}px 1fr`,
                }}
            >
                {/* ROW PLATE GRID */}
                <div
                    className="well-plate"
                    key={`well-plate-${selectedCell}`}
                    style={{
                        width: PLATE_WIDTH,
                        height: PLATE_HEIGHT,
                        gridArea: "2 / 2 / 3 / 3",
                        display: "grid",
                        gridTemplateRows: `repeat(${numRows}, 1fr)`,
                        gridTemplateColumns: `repeat(${numCols}, 1fr)`,
                        backgroundColor: theme.palette.neutral.light,
                    }}
                >
                    {[...Array(plateSize)].map((_, i) => {
                        const index = i + plateSize * wellPlates.getCurrentPlateIndex();
                        const coords = numberTo3dCoords(
                            index,
                            numRows,
                            numCols,
                            wellPlates.getNumPlates()
                        );
                        const itemAtIndex = selectItemAtIndex(index, samples, options, numPlates);
                        const isUnusedCell = unusedIndexes.includes(index % plateSize);
                        return (
                            <div
                                key={`gridsquare-${i}`}
                                style={{
                                    // Converting from zero-index to CSS grid 1-index
                                    display: "grid",
                                    gridColumnStart: coords.col + 1,
                                    gridColumnEnd: coords.col + 2,
                                    gridRowStart: coords.row + 1,
                                    gridRowEnd: coords.row + 2,
                                    backgroundColor:
                                        index === selectedCell
                                            ? theme.palette.secondary.light
                                            : theme.palette.neutral.light,
                                    padding: 3,
                                }}
                            >
                                <WellPlateCircle
                                    isUnusedCell={isUnusedCell}
                                    itemAtIndex={itemAtIndex}
                                    onClick={() => {
                                        if (isUnusedCell) return;
                                        wellPlates.setSelectedItem(index);
                                    }}
                                    wellColor={
                                        itemAtIndex
                                            ? wellPlates.getColorForSpecies(itemAtIndex.taxonKey)
                                            : theme.palette.neutral.dark
                                    }
                                />
                            </div>
                        );
                    })}
                </div>

                {/* ROW LEGEND (1-12) */}
                <div
                    className="row-legend"
                    style={{
                        width: PLATE_WIDTH,
                        height: LEGEND_WIDTH,
                        gridArea: "1 / 2 / 2 / 3",
                        display: "grid",
                        gridTemplateColumns: `repeat(${numCols}, 1fr)`,
                    }}
                >
                    {[...Array(numCols)].map((_, i) => (
                        <div
                            style={{ display: "flex", justifyContent: "center" }}
                            key={`row-legend-${i}`}
                        >
                            <span>{i + 1}</span>
                        </div>
                    ))}
                </div>

                {/* COLUMN LEGEND (A-H) */}
                <div
                    className="col-legend"
                    style={{
                        width: LEGEND_WIDTH,
                        height: PLATE_HEIGHT,
                        gridArea: "2 / 1 / 3 / 2",
                        display: "grid",
                        gridTemplateRows: `repeat(${numRows}, 1fr)`,
                    }}
                >
                    {[...Array(numRows)].map((_, i) => (
                        <div
                            style={{ display: "grid", alignContent: "center" }}
                            key={`col-legend-${i}`}
                        >
                            <span>{numberToLetter(i)}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* PAGINATION */}
            <div className={classes.paginationWrapper}>
                <Pagination
                    count={numPlates}
                    style={{ justifySelf: "center" }}
                    color="secondary"
                    page={currentPlate}
                    onChange={(event: object, page: number) => {
                        wellPlates.setCurrentPlateIndex(page - 1, options);
                    }}
                />
            </div>
        </div>
    );
}

export default WellPlateGrid;
