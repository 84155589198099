import React from "react";
import NewItemForm from "../../components/add-new-modal/new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { IncidentalObservationPost, ObservationType } from "../../types";
import { v4 as uuidv4 } from "uuid";
import { newIncidentalObservationFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewIncidentalObservationFormProps = {
    handleClose: () => void;
};

function NewIncidentalObservationForm({ handleClose }: NewIncidentalObservationFormProps) {
    const { submit, setPostValue, displayData, errors, savingStatus } =
        useNewItemForm<IncidentalObservationPost>("observation", newIncidentalObservationFields, [
            { key: "observationId", value: uuidv4() },
            { key: "observationDate", value: new Date() },
            { key: "observationType", value: ObservationType.SIGHTED },
        ]);

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={addItemModalStrings.addIncidentalObservation}
            subheading={addItemModalStrings.addSampleSubheading}
            formFields={newIncidentalObservationFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"samples/incidental-observation"}
            backToPage="samples"
        />
    );
}

export default NewIncidentalObservationForm;
