import { DetailPageSection } from "../../types";
import {
    disturbanceStatuses,
    landforms,
    habitats,
    soilTypes,
    soilColours,
} from "../../modules/listItemsFromEnums";
import { semicolonStringToCommaString, formatTzTimestamp } from "../../modules/formatters";

export const eventsForm: DetailPageSection[] = [
    {
        sectionTitle: "Event details",
        fields: [
            { label: "Event key", key: "eventKey", isReadOnly: true, inputType: "textfield" },
            { label: "Event ID", key: "eventID", isReadOnly: true, inputType: "textfield" },
            {
                label: "Collecting date",
                key: "eventDate",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatTzTimestamp,
            },
            { label: "Source", key: "eventSource", isReadOnly: true, inputType: "textfield" },
            {
                label: "Collector and people present",
                key: "peoplePresent",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: semicolonStringToCommaString,
            },
            { label: "Locality", key: "locality", isReadOnly: false, inputType: "textfield" },
        ],
    },
    {
        sectionTitle: "Tenure",
        /*
        National Park, State Conservation Area, State forest, Nature Reserve, Other Tenure
        */
        fields: [
            {
                label: "National Park",
                key: "nationalPark",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Nature Reserve",
                key: "natureReserve",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "State Conservation Area",
                key: "stateConservationArea",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "State Forest",
                key: "stateForest",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Other Tenure",
                key: "otherTenure",
                isReadOnly: false,
                inputType: "textfield",
            },
        ],
    },
    {
        sectionTitle: "Site information",
        fields: [
            {
                label: "Soil colour",
                key: "soilColour",
                isReadOnly: false,
                options: soilColours,
                inputType: "filter",
            },
            {
                label: "Soil texture",
                key: "soilTexture",
                isReadOnly: false,
                options: soilTypes,
                inputType: "filter",
            },
            {
                label: "Disturbance",
                key: "disturbanceHistory",
                isReadOnly: false,
                options: disturbanceStatuses,
                inputType: "checkboxes",
            },
            {
                label: "Disturbance other",
                key: "otherDisturbance",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Landform",
                key: "landformPrimary",
                isReadOnly: false,
                options: landforms,
                inputType: "checkboxes",
            },
            {
                label: "Landform other",
                key: "otherLandform",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Habitat",
                key: "habitat",
                isReadOnly: false,
                options: habitats,
                inputType: "checkboxes",
            },
            {
                label: "Habitat other",
                key: "otherHabitat",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Associated vegetation",
                key: "associatedVegetation",
                isReadOnly: false,
                inputType: "textfield",
            },
            {
                label: "Site Notes",
                key: "siteNotes",
                isReadOnly: false,
                inputType: "textfield",
            },
        ],
    },
];
