import {
    DisturbanceHistoryPrimary,
    LandformsPrimary,
    HabitatPrimary,
    SoilColours,
    SoilTextures,
    SourceOfCoordinates,
    Flowers,
    Fruit,
    StateSamplingStatus,
    TaxonPriority,
    TaxonType,
    ProvisionalAcceptedStatus,
    DArTService,
    DArTAnalysisStatus,
    MapItemType,
    SampleListItemType,
    TissueSampleType,
    HybridStatus,
    TrueOrFalseType,
    InfraSpecificRank,
    EditableProvisionalStatusFields,
} from "../types";
import { FilterListItem } from "../components/filter-dropdown/filter-dropdown";

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

function enumToFilterList<E extends Enum<E>>(inputEnum: E): FilterListItem[] {
    return Object.entries(inputEnum).map(([key, value]) => {
        // Mark value as coming from the old app
        const isLegacyValue = key.includes("_LEGACY") ? true : false;

        return { key: value, label: value, isLegacyValue: isLegacyValue };
    });
}

function enumToSimpleList<E extends Enum<E>>(inputEnum: E): string[] {
    return Object.entries(inputEnum).map(([key, value]) => {
        return value;
    });
}

// EVENTS
export const disturbanceStatuses = enumToFilterList(DisturbanceHistoryPrimary);
export const landforms = enumToFilterList(LandformsPrimary);
export const habitats = enumToFilterList(HabitatPrimary);
export const soilTypes = enumToFilterList(SoilTextures);
export const soilColours = enumToFilterList(SoilColours);

// SPECIES
export const samplingStatuses = enumToFilterList(StateSamplingStatus);
export const taxonPriorities = enumToFilterList(TaxonPriority);
export const taxonTypes = enumToFilterList(TaxonType);
export const provisionalStatusTypes = enumToFilterList(ProvisionalAcceptedStatus);
export const editableProvisionalTypes = enumToFilterList(EditableProvisionalStatusFields);
export const infraSpecificRank = [...enumToFilterList(InfraSpecificRank), { key: "-", label: "" }];

// SAMPLES
export const sourcesOfCoordinates = enumToFilterList(SourceOfCoordinates);
export const flowerTypes = enumToFilterList(Flowers);
export const fruitTypes = enumToFilterList(Fruit);
export const sampleTypes = enumToFilterList(SampleListItemType);
export const tissueSampleTypes = enumToFilterList(TissueSampleType);
export const hybridStatuses = enumToFilterList(HybridStatus);
export const trueOrFalseTypes = enumToFilterList(TrueOrFalseType);

// ORDERS
export const dartServices = enumToFilterList(DArTService);
export const dartAnalysisStatuses = [
    ...enumToFilterList(DArTAnalysisStatus),
    { key: "-", label: "-" },
];

// MAP
export const mapItemTypes: MapItemType[] = enumToSimpleList(MapItemType).map(t => t as MapItemType);
