import React from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ButtonOption } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        paper: {
            marginRight: theme.spacing(2),
        },
        tableHeaderButton: {
            height: 40,
            backgroundColor: "white",
            color: theme.palette.info.main,
            border: "1px solid #CCC",
            boxShadow: "none",
            textTransform: "uppercase",
        },
        menuList: {
            width: "100%",
            height: "auto",
            padding: 0,
        },
        menuItem: {
            padding: 0,
        },
        dropdownWrapper: {
            zIndex: 100,
        },
        menuItemInner: {
            padding: "8px 15px 6px 15px",
            textDecoration: "none",
            color: "inherit",
            width: "100%",
            height: "100%",
        },
    })
);

type DropdownButtonProps = {
    buttonLabel: string;
    buttonOptions?: ButtonOption[];
    startIcon?: JSX.Element;
};

export default function DropdownButton({
    buttonLabel,
    buttonOptions,
    startIcon,
}: DropdownButtonProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <Button
                    ref={anchorRef}
                    className={classes.tableHeaderButton}
                    endIcon={<ArrowDropDownIcon />}
                    startIcon={startIcon}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    {buttonLabel}
                </Button>
                <Popper
                    open={open}
                    className={classes.dropdownWrapper}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        className={classes.menuList}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {buttonOptions?.map((option, i) => {
                                            // The hardcoded width here is unfortunate. TODO: fix if possible
                                            if (option.downloadHref) {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        style={{ width: 100 }}
                                                        className={classes.menuItem}
                                                    >
                                                        <a
                                                            className={classes.menuItemInner}
                                                            href={option.downloadHref}
                                                            download={
                                                                option.downloadFilename || true
                                                            }
                                                        >
                                                            {option.label}
                                                        </a>
                                                    </MenuItem>
                                                );
                                            } else if (option.onClick) {
                                                return (
                                                    <MenuItem
                                                        key={i}
                                                        style={{ width: 160 }}
                                                        className={classes.menuItem}
                                                    >
                                                        <div
                                                            className={classes.menuItemInner}
                                                            onClick={() => {
                                                                option.onClick && option.onClick();
                                                            }}
                                                        >
                                                            {option.label}
                                                        </div>
                                                    </MenuItem>
                                                );
                                            }
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}
