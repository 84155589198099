import { DetailKey, DetailPageSection, DetailPageField, DartOrderDetailSpecies } from "../../types";
import { DetailPath } from "../api";
import { FilterListItem } from "../../components/filter-dropdown/filter-dropdown";

export const getFieldByKey = (
    key: DetailKey,
    sections: DetailPageSection[]
): DetailPageField | undefined => {
    // Get all the fields out of the sections
    const detailFields = sections.reduce((acc, curr) => {
        return [...acc, ...curr.fields];
    }, [] as DetailPageField[]);

    // Return the field with the corresponding key
    return detailFields.find(f => f.key === key);
};

// For dropdowns
// The value being passed around to server may not be the one we want to display
// Hence the {key: (for server), value: (for human display)} structure
export const getFilterLabelByKey = (
    menuItems: FilterListItem[] | undefined,
    key: string | undefined
): string => {
    let displayValue: string;
    if (!menuItems) {
        displayValue = "";
    } else {
        const foundItem = menuItems.find(item => item.key === key);
        displayValue = foundItem ? foundItem.label : "";
    }
    return displayValue;
};

export const addEmptyFilterOption = (
    menuItems: FilterListItem[] | undefined,
    emptyLabel: string
): FilterListItem[] => {
    const emptyOption: FilterListItem = { key: "", label: `(${emptyLabel})` };
    return menuItems ? [...menuItems, emptyOption] : [emptyOption];
};

// Return the "id key" of a form, given the detail path
export const detailPathToKey = (detailPath: DetailPath): DetailKey => {
    switch (detailPath) {
        case "voucher-sample":
            return "NSWnumber";
        case "tissue-sample":
            return "NSWnumber";
        case "observation":
            return "observationId";
        case "sampling-event":
            return "eventKey";
        case "dart-order":
            return "serviceNumber";
        case "taxon":
            return "taxonKey";
        default:
            throw new Error("Invalid path supplied to detailPathToKey");
    }
};

export const parseEventAutocompleteResponse = (responseJson: any): FilterListItem[] => {
    if (!responseJson.results) return [];

    return responseJson.results.map((raw: { [key: string]: any }) => {
        const peoplePresent = raw.people_present ? raw.people_present.split(";").join(", ") : "";

        return {
            key: raw.event_key,
            label: `${raw.event_key} - ${raw.locality}${peoplePresent}`,
        };
    });
};

type UpdateConfig = {
    key: DetailKey;
    value: any;
    currentPatchData: Record<string, any>;
    currentDisplayData: Record<string, any>;
    parentKey?: string;
};

export const transformUpdateData = ({
    key,
    value,
    currentPatchData,
    currentDisplayData,
    parentKey,
}: UpdateConfig): Record<string, Record<string, any>> => {
    // Handle re-identification fields
    if (["taxonKey", "dateIdentified", "identificationRemarks", "identifiedBy"].includes(key)) {
        return {
            patchUpdate: {
                identification: { ...currentPatchData?.identification, [key]: value },
            },
            displayUpdate: { [key]: value },
        };
    }
    // "sampleAndSpecies" is a special frontend key
    // Because dart-order samples and species must be updated at the same time on backend
    // Frontend species have more info than the backend requires
    else if (key === "sampleAndSpecies") {
        const patchSpecies = value.species.map(
            ({ acceptedName, genus, species, ...rest }: DartOrderDetailSpecies) => rest
        );

        return {
            patchUpdate: {
                samples: value.samples,
                species: patchSpecies,
            },
            displayUpdate: {
                samples: value.samples,
                species: value.species,
            },
        };
    }

    // Handle nested data
    if (parentKey) {
        return {
            patchUpdate: {
                [parentKey]: currentPatchData[parentKey]
                    ? { ...currentPatchData[parentKey], [key]: value }
                    : { [key]: value },
            },
            displayUpdate: currentDisplayData[parentKey]
                ? { [parentKey]: { ...currentDisplayData[parentKey], [key]: value } }
                : {},
        };
    }

    return {
        patchUpdate: { [key]: value },
        displayUpdate: { [key]: value },
    };
};
