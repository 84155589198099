import React from "react";
import NewItemForm from "../../components/add-new-modal/new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { SamplingEventPost, SamplingEventSource } from "../../types";
import { v4 as uuidv4 } from "uuid";
import { newEventFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewEventFormProps = {
    handleClose: () => void;
};

function NewEventForm({ handleClose }: NewEventFormProps) {
    const defaultUuid = uuidv4();

    const { submit, setPostValue, displayData, errors, savingStatus } =
        useNewItemForm<SamplingEventPost>("sampling-event", newEventFields, [
            { key: "eventID", value: defaultUuid },
            { key: "eventKey", value: defaultUuid },
            { key: "eventDate", value: new Date() },
            { key: "eventSource", value: SamplingEventSource.MANUAL },
        ]);

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={addItemModalStrings.addEvent}
            subheading={addItemModalStrings.addEventSubheading}
            formFields={newEventFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"events"}
            backToPage="events"
        />
    );
}

export default NewEventForm;
