import { DetailPageSection } from "../../types";
import { formatStatus, formatTzTimestamp } from "../../modules/formatters";

export const importForm: DetailPageSection[] = [
    {
        sectionTitle: "Import details",
        fields: [
            { label: "Import type", key: "importType", isReadOnly: true, inputType: "textfield" },
            {
                label: "Upload date",
                key: "timestamp",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatTzTimestamp,
            },
            {
                label: "Upload status",
                key: "progress",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: formatStatus,
            },
        ],
    },
];
