import React from "react";
import NewItemForm from "../../components/add-new-modal/new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { CommonSamplePost } from "../../types";
import { newCommonSampleFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewVoucherSampleFormProps = {
    handleClose: () => void;
};

function NewVoucherSampleForm({ handleClose }: NewVoucherSampleFormProps) {
    const { submit, setPostValue, displayData, errors, savingStatus } =
        useNewItemForm<CommonSamplePost>("voucher-sample", newCommonSampleFields, [
            { key: "sampleDate", value: new Date() },
        ]);

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={addItemModalStrings.addVoucherSample}
            subheading={addItemModalStrings.addSampleSubheading}
            formFields={newCommonSampleFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"samples/voucher"}
            backToPage="samples"
        />
    );
}

export default NewVoucherSampleForm;
