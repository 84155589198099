import { DataError } from "../../types";
import { strings } from "../strings/strings";
const { errorStrings } = strings;

export const checkFieldNotEmptyBlocksSaving = async (value: string): Promise<DataError> => {
    if (!value) {
        return {
            error: true,
            message: errorStrings.validation.fieldEmpty,
            blocksSaving: true,
        };
    } else {
        return { error: false };
    }
};
