import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { debounce } from "lodash/fp";
import qs from "qs";
import {
    DartOrderListItem,
    DartOrderListResponse,
    DartOrderListItemSpecies,
    PaginationInfo,
    QueryBase,
} from "../types";
import { api } from "../modules/api";
import { AppThunk } from "./store";
import { RootState } from "./root-reducer";
import { selectCurrentSearchQuery, setCurrentSearchQuery } from "./ui";

export type DartOrderListItemKey = keyof DartOrderListItem;
export type DartOrderListItemSpeciesKey = keyof DartOrderListItemSpecies;

export type DartOrderQuery = {
    genus?: string;
    species?: string;
    project?: string;
} & QueryBase;

export type FrontendDartOrderListItem = {
    acceptedName: string;
    analysis: string;
} & DartOrderListItem;
export type FrontendDartOrderListItemKey = keyof FrontendDartOrderListItem;

export type DartOrderState = {
    searchResult?: DartOrderListResponse;
    orders?: FrontendDartOrderListItem[];
    activeServiceNumber?: string;
    loading: boolean;
    paginationPage: number;
};

export type SampleListItemKey = keyof DartOrderListItem;

const initialState: DartOrderState = {
    activeServiceNumber: undefined,
    loading: false,
    paginationPage: 0,
};

const dartOrderSlice = createSlice({
    initialState,
    name: "orders",
    reducers: {
        setDartOrders(state, action: PayloadAction<DartOrderListResponse>) {
            state.searchResult = action.payload;
            // Add extra info in that's required by the frontend table
            const frontendOrders: FrontendDartOrderListItem[] = action.payload.results.map(
                order => {
                    // Slightly YOLO, make sure on FE that no dodgy species records come through
                    const cleanedSpecies = order.species.filter(
                        s => !(s.acceptedName === null && s.sampleCount === 0)
                    );
                    return {
                        ...order,
                        acceptedName: `${cleanedSpecies.length} species`,
                        analysis: "...",
                        species: cleanedSpecies,
                    };
                }
            );
            state.orders = frontendOrders;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
    },
});
export default dartOrderSlice;

// ----------------------------------------------------------------------------
// Selectors

export const selectOrderResults = (state: RootState): DartOrderListItem[] | undefined => {
    return state?.orders.orders;
};

export const selectOrderPagination = (state: RootState): PaginationInfo | undefined => {
    return state?.orders.searchResult?.pagination;
};

export const selectOrdersIsLoading = (state: RootState): boolean => {
    return state?.orders.loading;
};

// // ----------------------------------------------------------------------------
// // Thunks

// needs to be debounced since we will fire this off based on the search input field.
// debounced fn needs to have the inner function defined outside
const searchInner = debounce(
    50,
    async (dispatch: Dispatch, sampleListQuery: DartOrderQuery, getState) => {
        dispatch(dartOrderSlice.actions.setLoading(true));

        const query = qs.stringify(sampleListQuery);
        // set the current search query in the ui state
        await dispatch(setCurrentSearchQuery(query));

        const res = await api(`dart-order?${query}`);
        const data = await res.json();

        // the response can sometimes come back later than the next search request
        // so we need to check that the current search query is the same as the one
        // we just got a response for [else return].
        const currentSearchQuery = selectCurrentSearchQuery(getState());
        if (currentSearchQuery && currentSearchQuery !== query) return null;

        dispatch(dartOrderSlice.actions.setDartOrders(data));
        dispatch(dartOrderSlice.actions.setLoading(false));
        dispatch(setCurrentSearchQuery("")); //reset the current search query
    }
);

export const searchDartOrders = (dartOrderQuery: DartOrderQuery): AppThunk => {
    return (dispatch, getState) => {
        searchInner(dispatch, dartOrderQuery, getState);
    };
};
