import React, { useState, useEffect } from "react";
import { Point, Position } from "geojson";
import DetailFormTextfield from "../detail-page-textfield/detail-page-textfield";
import { DataError } from "../../types";

export type Coord = keyof FrontendCoords;

// We need this because the whole "value" object could be null
// We need to show something and allow the user to edit
type FrontendCoords = { lat: string; long: string };

type CoordinatesFieldProps = {
    handleChange: (newValue: any) => void;
    isReadOnly: boolean;
    value: Point | null;
    error: DataError | undefined;
};

function CoordinatesField({ handleChange, isReadOnly, value, error }: CoordinatesFieldProps) {
    const [frontendCoords, setFrontendCoords] = useState<FrontendCoords>({ lat: "", long: "" });

    useEffect(() => {
        // Value may be null, so we need to deal with that
        const coords: Position | undefined = value?.coordinates;
        const frontendLat = coords ? coords[1].toString() : "";
        const frontendLong = coords ? coords[0].toString() : "";
        setFrontendCoords({ lat: frontendLat, long: frontendLong });
    }, [value]);

    const handleCoordinatesChange = (val: string, coord: Coord) => {
        const feCoords: FrontendCoords = { ...frontendCoords, [coord]: val };
        setFrontendCoords(feCoords);

        handleChange({
            ...value,
            type: "Point",
            coordinates: [feCoords.long, feCoords.lat],
        });
    };

    return (
        <>
            <DetailFormTextfield
                label={"Latitude"}
                value={frontendCoords.lat}
                error={error?.fieldMessages?.get("lat")}
                isReadOnly={isReadOnly}
                handleChange={e => {
                    const val = e.target.value;
                    handleCoordinatesChange(val, "lat");
                }}
            />
            <DetailFormTextfield
                label={"Longitude"}
                value={frontendCoords.long}
                error={error?.fieldMessages?.get("long")}
                isReadOnly={isReadOnly}
                handleChange={e => {
                    const val = e.target.value;
                    handleCoordinatesChange(val, "long");
                }}
            />
        </>
    );
}

export default CoordinatesField;
