import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
    collectionInfo,
    coordinates,
    storage,
    identification,
    populationInformation,
    destructiveSamplingFormSection,
} from "./sample-detail-config";
import { api } from "../../modules/api";
import { history } from "../../pages/root";
import { TissueSamplePatch, TissueSampleDetail } from "../../types";
import { useDetailForm } from "../../hooks/useDetailForm";
import DetailPage from "../../components/detail-page/detail-page";
import SampleDetailDartOrders from "./sample-detail-dartorders";
import DetailPageCorrections from "../../components/detail-page-corrections/detail-page-corrections";
import DetailFormFieldWrapper from "../../components/detail-form/detail-form-field-wrapper";
import ArchiveButton from "../../components/button/archive-button";

const sampleTissueForm = [
    collectionInfo,
    coordinates,
    destructiveSamplingFormSection,
    storage,
    identification,
    populationInformation,
];

function SamplesTissueDetailPage() {
    let { id }: any = useParams();

    // TODO: move this to redux, which means handle archive can be moved to the component
    const [archiveError, setArchiveError] = useState(false);

    const {
        displayData,
        patchData,
        setPatchValue,
        undoUnsavedChanges,
        submit,
        errors,
        savingStatus,
    } = useDetailForm<TissueSampleDetail, TissueSamplePatch>(
        id,
        "tissue-sample",
        "tissue-sample",
        sampleTissueForm
    );

    const handleArchive = () => {
        const confirmDelete = window.confirm(
            `Are you sure you want to archive Species "${displayData["acceptedName"]}"?`
        );

        if (confirmDelete) {
            api(`tissue-sample/archive/${id}`, { method: "POST" }, true).then(data => {
                if (data.status === 200) {
                    history.push({
                        pathname: `/dashboard/samples`,
                    });
                } else {
                    setArchiveError(true);
                }
            });
        }
    };

    const getHeaderTitle = () => {
        const archivedString = displayData["archived"] ? " [ARCHIVED]" : "";
        return `Tissue Sample: ${displayData.NSWnumber || ""}${archivedString}`;
    };

    return (
        <div>
            {displayData && (
                <>
                    <DetailPage
                        id={id}
                        header={getHeaderTitle()}
                        formSections={sampleTissueForm}
                        displayData={displayData}
                        patchData={patchData}
                        submit={submit}
                        setPatchValue={setPatchValue}
                        undoUnsavedChanges={undoUnsavedChanges}
                        errors={errors}
                        savingStatus={savingStatus}
                        detailPagePath={"samples/tissue"}
                        backToPage={"sample details"}
                    />
                    <SampleDetailDartOrders
                        orderCounts={displayData.dartOrderCounts}
                        backToPage={"sample details"}
                    />
                    {Boolean(displayData.corrections?.length) && (
                        <DetailPageCorrections corrections={displayData.corrections} />
                    )}

                    {/* Archive button display */}
                    <div style={{ marginTop: 80 }}>
                        <DetailFormFieldWrapper
                            body={
                                <ArchiveButton
                                    modelBaseToArchive="sample"
                                    handleArchive={handleArchive}
                                    isArchived={displayData["archived"]}
                                    archiveError={archiveError}
                                />
                            }
                            isReadOnly={true}
                            error={{ error: archiveError }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default SamplesTissueDetailPage;
