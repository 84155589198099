import React, { useMemo, useEffect, useState } from "react";
import { parseUrlParams, stringifyUrlParams } from "../../modules/query-helpers";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useSelector } from "../../ducks/root-reducer";
import { ColumnKey } from "../../components/info-table/info-table";
import InfoTable from "../../components/info-table/info-table";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import {
    SamplingEventQuery,
    searchEvents,
    selectEventResults,
    selectEventPagination,
} from "../../ducks/events";
import { setQuery, SortDirection } from "../../ducks/constant-data";
import TableHeader from "../../components/table-header/table-header";
import SearchBar from "../../components/search-bar/search-bar";
import { BASE_API_URL } from "../../modules/api";
import AddNewModal from "../../components/add-new-modal/add-new-modal";
import NewEventForm from "../../components/add-new-modal/new-event-form";
import { sortableColumns } from "../../types";
import { eventColumns } from "./events-page-config";

function EventsPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const eventResults = useSelector(selectEventResults);
    const pagination = useSelector(selectEventPagination);
    const isLoading = useSelector<boolean>(s => s.events.loading);
    const [newModalOpen, setNewModalOpen] = useState<boolean>(false);

    const eventListQuery: SamplingEventQuery = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    useEffect(() => {
        dispatch(searchEvents(eventListQuery));
    }, [dispatch, eventListQuery]);

    const { sortBy, sortDirection, query } = eventListQuery;

    const downloadLink = useMemo(() => {
        const { page, ...paramsWithoutPage } = eventListQuery;
        const stringParams = paramsWithoutPage ? `${stringifyUrlParams(paramsWithoutPage)}` : "";

        return `${BASE_API_URL}/sampling-event.csv?${stringParams}`;
    }, [eventListQuery]);

    return (
        <div style={{ minHeight: 500, position: "relative", height: "100%", width: "100%" }}>
            {isLoading && <LoadingOverlay />}
            <TableHeader
                title="Events"
                downloadOptions={[
                    {
                        label: "CSV",
                        downloadHref: downloadLink,
                    },
                ]}
                createNewOptions={[
                    {
                        label: "New Event",
                        onClick: () => {
                            setNewModalOpen(true);
                        },
                    },
                ]}
            />
            <SearchBar
                placeHolder={"Event key"}
                query={query}
                onKeyUp={newQuery => {
                    dispatch(setQuery(newQuery));
                }}
            />
            {eventResults && (
                <InfoTable
                    rows={eventResults}
                    columns={eventColumns}
                    sortableColumns={sortableColumns.events}
                    pagination={pagination}
                    sortBy={sortBy as ColumnKey}
                    sortDirection={sortDirection as SortDirection}
                    backToPage={"events"}
                />
            )}
            <AddNewModal
                isOpen={newModalOpen}
                handleClose={() => {
                    setNewModalOpen(false);
                }}
                body={
                    <NewEventForm
                        handleClose={() => {
                            setNewModalOpen(false);
                        }}
                    />
                }
            />
        </div>
    );
}

export default EventsPage;
