import React from "react";
import NewItemForm from "../../components/add-new-modal/new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { TaxonPost } from "../../types";
import { newTaxonFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewSpeciesFormProps = {
    handleClose: () => void;
};

function NewSpeciesForm({ handleClose }: NewSpeciesFormProps) {
    const { submit, setPostValue, displayData, errors, savingStatus } = useNewItemForm<TaxonPost>(
        "taxon",
        newTaxonFields,
        []
    );

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={addItemModalStrings.addSpecies}
            subheading={addItemModalStrings.addSpeciesSubheading}
            formFields={newTaxonFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"species"}
            backToPage="species"
        />
    );
}

export default NewSpeciesForm;
