import { numberToPlatePosition } from "../../modules/numberToCoords";
import { PlateOptions } from "./well-plate-config";
import { DartOrderDetailSample, DartOrderDetailSpecies, DArTAnalysisStatus } from "../../types";
import { PlateItemKey } from "../../hooks/useWellPlate";

type SamplesAndSpecies = {
    samples: DartOrderDetailSample[];
    species: DartOrderDetailSpecies[];
};

// Get the sample stored at a particular index
export const selectItemAtIndex = (
    index: number,
    samples: DartOrderDetailSample[],
    options: PlateOptions,
    numPlates: number
): DartOrderDetailSample | undefined => {
    const { row, column, plateNumber } = numberToPlatePosition(
        index,
        options.numRows,
        options.numCols,
        numPlates
    );
    return samples?.find(
        s => s.column === column && s.row === row && s.plateNumber === plateNumber
    );
};

// Simple "set" version of add. If the species already exists, don't add it again
export const addSpecies = (
    species: DartOrderDetailSpecies,
    allSpecies: DartOrderDetailSpecies[]
): DartOrderDetailSpecies[] => {
    const alreadyExists = allSpecies.find(s => s.taxonKey === species.taxonKey);
    return alreadyExists ? allSpecies : [...allSpecies, species];
};

// Remove a sample, keeping the species list updated
export const removeSample = (
    sampleToDelete: DartOrderDetailSample,
    allSamples: DartOrderDetailSample[],
    allSpecies: DartOrderDetailSpecies[]
): SamplesAndSpecies => {
    // Remove the sample
    const newSamples = allSamples.filter(s => {
        return s.displayNswNumber !== sampleToDelete.displayNswNumber;
    });
    // Remove the species, if this was the last one in the well plate
    const speciesStillExists = newSamples.find(s => s.taxonKey === sampleToDelete.taxonKey);
    const newSpecies = speciesStillExists
        ? [...allSpecies]
        : allSpecies.filter(sp => sp.taxonKey !== sampleToDelete.taxonKey);

    return {
        samples: newSamples,
        species: newSpecies,
    };
};

// Add or remove sample from plate
export const setSampleIsInPlate = (
    sample: DartOrderDetailSample,
    isInPlate: boolean,
    allSamples: DartOrderDetailSample[],
    species: DartOrderDetailSpecies[]
): SamplesAndSpecies => {
    const newSamples = allSamples.map(s => {
        return s.column === sample.column &&
            s.row === sample.row &&
            s.plateNumber === sample.plateNumber
            ? { ...s, inPlate: isInPlate }
            : s;
    });
    return {
        samples: newSamples,
        species: species,
    };
};

// Change the analysis status of a species
export const setAnalysisStatusForSpecies = (
    speciesToUpdate: DartOrderDetailSpecies,
    analysisStatus: DArTAnalysisStatus,
    allSpecies: DartOrderDetailSpecies[],
    allSamples: DartOrderDetailSample[]
): SamplesAndSpecies => {
    const newSpecies = allSpecies.map(s => {
        return s.taxonKey === speciesToUpdate.taxonKey
            ? { ...s, analysisStatus: analysisStatus }
            : s;
    });
    return {
        samples: allSamples,
        species: newSpecies,
    };
};

// Change the analysis status of a species
export const setIsFinalOrderForSpecies = (
    speciesToUpdate: DartOrderDetailSpecies,
    isFinalOrder: boolean,
    allSpecies: DartOrderDetailSpecies[],
    allSamples: DartOrderDetailSample[]
): SamplesAndSpecies => {
    const newSpecies = allSpecies.map(s => {
        return s.taxonKey === speciesToUpdate.taxonKey ? { ...s, isFinalOrder: isFinalOrder } : s;
    });
    return {
        samples: allSamples,
        species: newSpecies,
    };
};

export const removePlate = (
    samples: DartOrderDetailSample[],
    species: DartOrderDetailSpecies[],
    plateNumberToRemove: number
): SamplesAndSpecies => {
    // Remove items on the plate
    // Also down the plate number of items on any subsequent plates
    const samplesToKeep = samples
        .filter(s => s.plateNumber && s.plateNumber !== plateNumberToRemove)
        .map(s => {
            return s.plateNumber > plateNumberToRemove
                ? { ...s, plateNumber: s.plateNumber - 1 }
                : s;
        });
    // Also make sure that if we've removed the last example of a species, it's removed from the species list
    const speciesToKeep = species.filter(s =>
        samplesToKeep.find(sample => sample.taxonKey === s.taxonKey)
    );

    return {
        samples: samplesToKeep,
        species: speciesToKeep,
    };
};

// The table column keys "organism" and "species" are not actually in the data
// So to get these, we need to split the taxon
export function keyToValue(
    key: PlateItemKey,
    sample: DartOrderDetailSample,
    species: DartOrderDetailSpecies[]
): string | number {
    const matchingSpecies = species.find(s => s.taxonKey === sample.taxonKey);
    switch (key) {
        case "organism":
            return matchingSpecies ? matchingSpecies.genus : "-";
        case "species":
            return matchingSpecies ? matchingSpecies.species : "-";
        case "inPlate":
            return sample.inPlate ? "true" : "false";
        default:
            return sample[key];
    }
}
