import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { debounce } from "lodash/fp";
import qs from "qs";
import { api } from "../modules/api";
import { RootState } from "./root-reducer";
import { AppThunk } from "./store";
import {
    SamplesListItem,
    SamplesListResponse,
    TissueSampleDetail,
    VoucherSampleDetail,
    IncidentalObservationDetail,
    PaginationInfo,
    QueryBase,
    DataError,
} from "../types";
import { checkValidLatLong } from "../modules/validation/checkValidCoordinates";
import { selectCurrentSearchQuery, setCurrentSearchQuery } from "./ui";

export type SampleListQuery = {
    genus?: string;
    species?: string;
    type?: string;
    tissueSampleType?: string;
    project?: string;
} & QueryBase;

export type FrontendSamplesListItem = {
    error: DataError;
} & SamplesListItem;

export type SampleListState = {
    searchResult?: SamplesListResponse;
    samples?: FrontendSamplesListItem[];
    activeTissueSample?: TissueSampleDetail;
    activeVoucherSample?: VoucherSampleDetail;
    activeIncidentalSample?: IncidentalObservationDetail;
    loading: boolean;
    paginationPage: number;
};

export type SampleListItemKey = keyof FrontendSamplesListItem;

const initialState: SampleListState = {
    loading: false,
    paginationPage: 0,
};

const sampleSlice = createSlice({
    initialState,
    name: "samples",
    reducers: {
        setSearchResult(state, action: PayloadAction<SamplesListResponse>) {
            state.searchResult = action.payload;
        },
        setSamples(state, action: PayloadAction<FrontendSamplesListItem[]>) {
            state.samples = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setActiveTissueSample(state, action: PayloadAction<TissueSampleDetail>) {
            state.activeTissueSample = action.payload;
        },
        setActiveVoucherSample(state, action: PayloadAction<VoucherSampleDetail>) {
            state.activeVoucherSample = action.payload;
        },
        setActiveIncidentalSample(state, action: PayloadAction<IncidentalObservationDetail>) {
            state.activeIncidentalSample = action.payload;
        },
    },
});
export default sampleSlice;

export const { setSearchResult } = sampleSlice.actions;

// ----------------------------------------------------------------------------
// Selectors

export const selectSampleResults = (state: RootState): FrontendSamplesListItem[] | undefined => {
    return state?.samples.samples;
};

export const selectSamplePagination = (state: RootState): PaginationInfo | undefined => {
    return state?.samples.searchResult?.pagination;
};

export const selectSamplesIsLoading = (state: RootState): boolean => {
    return state?.samples.loading;
};

export const selectActiveTissueSample = (state: RootState): TissueSampleDetail | undefined => {
    return state?.samples.activeTissueSample;
};

export const selectActiveVoucherSample = (state: RootState): VoucherSampleDetail | undefined => {
    return state?.samples.activeVoucherSample;
};

export const selectActiveIncidentalSample = (
    state: RootState
): IncidentalObservationDetail | undefined => {
    return state?.samples.activeIncidentalSample;
};

// ----------------------------------------------------------------------------
// Thunks

// needs to be debounced since we will fire this off based on the search input field.
// debounced fn needs to have the inner function defined outside
const searchInner = debounce(
    50,
    async (dispatch: Dispatch, sampleListQuery: SampleListQuery, getState) => {
        dispatch(sampleSlice.actions.setLoading(true));

        const query = qs.stringify(sampleListQuery);
        // set the current search query in the ui state
        await dispatch(setCurrentSearchQuery(query));

        const res = await api(`samples?${query}`);
        const data: SamplesListResponse = await res.json();

        // the response can sometimes come back later than the next search request
        // so we need to check that the current search query is the same as the one
        // we just got a response for [else return].
        const currentSearchQuery = selectCurrentSearchQuery(getState());
        if (currentSearchQuery && currentSearchQuery !== query) return null;

        const samplesWithErrors: FrontendSamplesListItem[] = data.results.map(sample => {
            const err = checkValidLatLong(sample.sampleLocation);
            return { ...sample, error: { error: err.error, message: err.message } };
        });

        dispatch(sampleSlice.actions.setSamples(samplesWithErrors));
        dispatch(sampleSlice.actions.setSearchResult(data));
        dispatch(sampleSlice.actions.setLoading(false));
        dispatch(setCurrentSearchQuery("")); //reset the current search query
    }
);

export const searchAssets = (sampleListQuery: SampleListQuery): AppThunk => {
    return (dispatch, getState) => {
        searchInner(dispatch, sampleListQuery, getState);
    };
};
