import React, { useMemo, useEffect, useState } from "react";
import qs from "qs";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { parseUrlParams } from "../../modules/query-helpers";
import { useSelector } from "../../ducks/root-reducer";
import { ColumnKey } from "../../components/info-table/info-table";
import InfoTable from "../../components/info-table/info-table";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import { setQuery, SortDirection } from "../../ducks/constant-data";
import TableHeader from "../../components/table-header/table-header";
import SearchBar from "../../components/search-bar/search-bar";
import AddNewModal from "../../components/add-new-modal/add-new-modal";
import { importColumns } from "./imports-page-config";
import { ImportRecordQuery } from "../../ducks/imports";
import NewImportForm from "../../components/add-new-modal/new-import-form";
import { api } from "../../modules/api";
import { ImportRecordListItem, PaginationInfo, sortableColumns } from "../../types";

function ImportsPage() {
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector<boolean>(s => s.imports.loading);
    const [newModalOpen, setNewModalOpen] = useState<boolean>(false);
    const [repairModalOpen, setRepairModalOpen] = useState<boolean>(false);
    const [data, setData] = useState<ImportRecordListItem[]>([]);
    const [pagination, setPagination] = useState<PaginationInfo | undefined>(undefined);

    const importRecordQuery: ImportRecordQuery = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    useEffect(() => {
        const loadData = async (): Promise<ImportRecordListItem[]> => {
            const query = qs.stringify(importRecordQuery);
            const response = await api(`import?${query}`);
            const responseJson = await response.json();

            return responseJson;
        };
        const load = () =>
            loadData().then(res => {
                setData((res as any).results);
                setPagination((res as any).pagination);
            });

        load();

        const interval = setInterval(() => {
            load();
        }, 2000);

        return () => clearInterval(interval);
    }, [dispatch, importRecordQuery]);

    const { sortBy, sortDirection, query } = importRecordQuery;

    return (
        <div
            data-cy="import-table"
            style={{ minHeight: 500, position: "relative", height: "100%", width: "100%" }}
        >
            {isLoading && <LoadingOverlay />}
            <TableHeader
                title="Imports"
                createNewOptions={[
                    {
                        label: "Import New Data",
                        onClick: () => {
                            setNewModalOpen(true);
                        },
                        dataCy: "import-species-sample-button",
                    },
                ]}
                extraOption={{
                    label: "Repair Data",
                    onClick: () => {
                        setRepairModalOpen(true);
                    },
                }}
            />
            <SearchBar
                placeHolder={"Search imports"}
                query={query}
                onKeyUp={newQuery => {
                    dispatch(setQuery(newQuery));
                }}
            />
            {data && (
                <InfoTable
                    rows={data}
                    columns={importColumns}
                    sortableColumns={sortableColumns.events}
                    pagination={pagination}
                    sortBy={sortBy as ColumnKey}
                    sortDirection={sortDirection as SortDirection}
                    backToPage={"import"}
                />
            )}
            <AddNewModal
                isOpen={newModalOpen}
                handleClose={() => {
                    setNewModalOpen(false);
                }}
                body={
                    <NewImportForm
                        handleClose={() => {
                            setNewModalOpen(false);
                        }}
                        isRepairImport={false}
                    />
                }
            />
            <AddNewModal
                isOpen={repairModalOpen}
                handleClose={() => {
                    setRepairModalOpen(false);
                }}
                body={
                    <NewImportForm
                        handleClose={() => {
                            setRepairModalOpen(false);
                        }}
                        isRepairImport={true}
                    />
                }
            />
        </div>
    );
}

export default ImportsPage;
