import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { AppPage } from "../../ducks/ui";
import AppLink from "../../components/app-link/app-link";

type DartOrderTableProps = {
    orderCounts: Record<string, number>;
    backToPage: AppPage;
};

function DartOrderTable({ orderCounts, backToPage }: DartOrderTableProps) {
    return (
        <Table>
            <TableBody>
                {Object.entries(orderCounts).map(([orderId, count], i) => {
                    const displayText = count > 1 ? `${orderId} [${count}]` : orderId;
                    const backgroundColor = i % 2 === 0 ? "#F9F9F9" : "white";

                    return (
                        <TableRow key={orderId} style={{ backgroundColor }}>
                            <TableCell>
                                <AppLink
                                    to={`/dashboard/orders/${orderId}`}
                                    innerText={displayText}
                                    backToPage={backToPage}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

type SampleDetailDartOrderProps = {
    orderCounts: Record<string, number>;
    backToPage: AppPage;
};

function SampleDetailDartOrders({ orderCounts, backToPage }: SampleDetailDartOrderProps) {
    return (
        <div style={{ margin: "30px 10px", width: 450 }}>
            <h3>DArT Orders</h3>
            {orderCounts && Object.keys(orderCounts).length ? (
                <DartOrderTable orderCounts={orderCounts} backToPage={backToPage} />
            ) : (
                <span>None</span>
            )}
        </div>
    );
}

export default SampleDetailDartOrders;
