import React from "react";
import NewItemForm from "./new-item-form";
import { useNewItemForm } from "../../hooks/useNewItemForm";
import { CommonSamplePost } from "../../types";
import { newCommonSampleFields } from "./new-item-field-config";
import { strings } from "../../modules/strings/strings";
const { addItemModalStrings } = strings;

type NewTissueSampleFormProps = {
    handleClose: () => void;
};

function NewTissueSampleForm({ handleClose }: NewTissueSampleFormProps) {
    const { submit, setPostValue, displayData, errors, savingStatus } =
        useNewItemForm<CommonSamplePost>("tissue-sample", newCommonSampleFields, [
            { key: "sampleDate", value: new Date() },
        ]);

    return (
        <NewItemForm
            onSubmit={submit}
            displayData={displayData}
            handleClose={handleClose}
            heading={addItemModalStrings.addTissueSample}
            subheading={addItemModalStrings.addSampleSubheading}
            formFields={newCommonSampleFields}
            setPostValue={setPostValue}
            errors={errors}
            savingStatus={savingStatus}
            newItemPath={"samples/tissue"}
            backToPage="samples"
        />
    );
}

export default NewTissueSampleForm;
