import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import {
    SpeciesFilterList,
    ProjectsFilterList,
    ProjectsFilterListItem,
    CollectorsList,
    SampleListItemType,
    TissueSampleType,
    MapItemType,
    TaxonType,
    ProvisionalAcceptedStatus,
    DetailPageCorrection,
    TrueOrFalseType,
    SpeciesFilterListItem,
} from "../types";
import { ColumnKey } from "../components/info-table/info-table";
import { api } from "../modules/api";
import { AppThunk } from "./store";
import { history } from "../pages/root";
import qs from "qs";
import { RootState } from "./root-reducer";
import { FilterListItem } from "../components/filter-dropdown/filter-dropdown";
import { uniqBy } from "lodash";

export type SortDirection = "asc" | "desc";
export type SortOptions = { sortBy: ColumnKey; sortDirection: SortDirection };

export type ConstantDataState = {
    activeGenus?: string;
    activeSpecies?: string;
    activeProject?: string;
    activeCollector?: string;
    activeType?: SampleListItemType | undefined;
    activeTaxonType?: TaxonType | undefined;
    activeProvisionalStatus?: ProvisionalAcceptedStatus | undefined;
    activeTissueSampleType?: TissueSampleType | undefined;
    activeArchivedStatus?: TrueOrFalseType | undefined;
    activeMapTypes?: MapItemType[];
    paginationPage: number;
    speciesByGenera: any;
    speciesWithGenera: FilterListItem[];
    speciesWithGeneraKeyByName: FilterListItem[];
    query?: string;
    collectors: FilterListItem[];
    sortingBy?: SortOptions | undefined;
} & SpeciesFilterList &
    ProjectsFilterList;

const initialState: ConstantDataState = {
    genera: [],
    speciesByGenera: {},
    speciesWithGenera: [],
    speciesWithGeneraKeyByName: [],
    projects: [],
    collectors: [],
    paginationPage: 0,
    activeType: undefined,
    activeTaxonType: undefined,
    activeProvisionalStatus: undefined,
    activeTissueSampleType: undefined,
    activeArchivedStatus: undefined,
};

export type DetailPageCorrectionKey = keyof DetailPageCorrection;

export const constantDataSlice = createSlice({
    initialState,
    name: "constant-data",
    reducers: {
        setSpeciesFilterList(state, action: PayloadAction<SpeciesFilterList>) {
            state.genera = action.payload.genera;
            state.speciesByGenera = action.payload.speciesByGenera;
            state.speciesByGenera = action.payload.speciesByGenera;
        },
        setSpeciesWithGenera(state, action: PayloadAction<FilterListItem[]>) {
            state.speciesWithGenera = action.payload;
        },
        setSpeciesWithGeneraKeyByName(state, action: PayloadAction<FilterListItem[]>) {
            state.speciesWithGeneraKeyByName = action.payload;
        },
        setProjectFilterList(state, action: PayloadAction<ProjectsFilterList>) {
            state.projects = action.payload.projects;
        },
        setCollectorList(state, action: PayloadAction<FilterListItem[]>) {
            state.collectors = action.payload;
        },
        setActiveSpecies(state, action: PayloadAction<string>) {
            state.activeSpecies = action.payload;
        },
        setActiveGenus(state, action: PayloadAction<string>) {
            state.activeGenus = action.payload;
        },
        setActiveType(state, action: PayloadAction<SampleListItemType | undefined>) {
            state.activeType = action.payload;
        },
        setActiveTaxonType(state, action: PayloadAction<TaxonType | undefined>) {
            state.activeTaxonType = action.payload;
        },
        setActiveProvisionalStatus(
            state,
            action: PayloadAction<ProvisionalAcceptedStatus | undefined>
        ) {
            state.activeProvisionalStatus = action.payload;
        },
        setActiveTissueSampleType(state, action: PayloadAction<TissueSampleType | undefined>) {
            state.activeTissueSampleType = action.payload;
        },
        setActiveArchivedStatus(state, action: PayloadAction<TrueOrFalseType | undefined>) {
            state.activeArchivedStatus = action.payload;
        },
        setActiveCollector(state, action: PayloadAction<string>) {
            state.activeCollector = action.payload;
        },
        setActiveMapTypes(state, action: PayloadAction<MapItemType[]>) {
            state.activeMapTypes = action.payload;
        },
        setActiveProject(state, action: PayloadAction<string>) {
            state.activeProject = action.payload;
        },
        setPaginationPage(state, action: PayloadAction<number>) {
            state.paginationPage = action.payload;
        },
        setSortingBy(state, action: PayloadAction<SortOptions>) {
            state.sortingBy = action.payload;
        },
        setQuery(state, action: PayloadAction<string>) {
            state.query = action.payload;
        },
    },
});

export default constantDataSlice;

export const {
    setSpeciesFilterList,
    setProjectFilterList,
    setSpeciesWithGenera,
    setSpeciesWithGeneraKeyByName,
} = constantDataSlice.actions;

// ----------------------------------------------------------------------------
// Selectors

export const selectProjectData = (state: RootState): ProjectsFilterListItem[] | undefined => {
    return state?.constantData?.projects;
};

export const selectCollectorsData = (state: RootState): FilterListItem[] | undefined => {
    return state?.constantData?.collectors;
};

export const selectSpeciesWithGenera = (state: RootState): FilterListItem[] | undefined => {
    return state?.constantData?.speciesWithGenera;
};

export const selectSpeciesWithGeneraKeyByName = (
    state: RootState
): FilterListItem[] | undefined => {
    return state?.constantData?.speciesWithGeneraKeyByName;
};

export const selectSpeciesByGenera = (state: RootState): any => {
    return state?.constantData?.speciesByGenera;
};

export const selectActiveProject = (state: RootState): string | undefined => {
    return state?.constantData?.activeProject;
};

export const selectActiveCollector = (state: RootState): string | undefined => {
    return state?.constantData?.activeCollector;
};

export const selectActiveType = (state: RootState): string | undefined => {
    return state?.constantData?.activeType;
};

export const selectActiveMapTypes = (state: RootState): MapItemType[] | undefined => {
    return state?.constantData?.activeMapTypes;
};

export const selectPaginationPage = (state: RootState): number => {
    if (state?.constantData?.paginationPage) {
        return state.constantData.paginationPage;
    } else {
        return 0;
    }
};

export const selectQuery = (state: RootState): string | undefined => {
    return state?.constantData.query;
};

export const selectTaxonByTaxonKey = (taxonKey: string, state: RootState): any => {
    return state.constantData.speciesWithGenera.find(taxon => taxon.key === taxonKey);
};

// Using createSelectors to memoize the result, ensuring the same reference is
// returned for the same state and taxonEventId, preventing unnecessary re-renders
const getGenera = (state: RootState) => state.constantData.genera;
export const selectGenusDataForDropdown = createSelector([getGenera], genera => {
    if (!genera) return [];

    return genera.map(genus => {
        return { key: genus, label: genus };
    });
});

const getSpeciesByGenera = (state: RootState) => state.constantData.speciesByGenera;
const getActiveGenus = (state: RootState) => state.constantData.activeGenus;
export const selectSpeciesDataForDropdown = createSelector(
    [getSpeciesByGenera, getActiveGenus],
    (speciesByGenera, activeGenus) => {
        // Return empty array if no species by genera or active genus
        if (!speciesByGenera || !activeGenus) return [];

        const speciesList = speciesByGenera[activeGenus];

        // Filter out null values
        const truthySpeciesList = speciesList.filter((e: SpeciesFilterListItem) => e.species);
        // Remove duplicate species labels from those samples that share species but
        // have additional taxonomical units specified
        const uniqueSpeciesList = uniqBy(
            truthySpeciesList,
            (sp: SpeciesFilterListItem) => sp.species
        );

        return uniqueSpeciesList.map((sp: any) => {
            return { key: sp.species, label: sp.species };
        });
    }
);

// ----------------------------------------------------------------------------
// Thunks

export function loadSpeciesData(): AppThunk {
    return async dispatch => {
        const response = await api("species-filter-list/");
        const data: SpeciesFilterList = await response.json();

        // Species with genera keyed by taxonKey, for detail page dropdowns
        const speciesWithGenera = Object.values(data.speciesByGenera).reduce((acc, curr) => {
            // Make sure that taxa are unique
            const uniqTaxa = uniqBy(curr, "species");

            const newTaxaNames: any = uniqTaxa.map(obj => {
                return { key: `${obj.taxonKey}`, label: `${obj.genus} ${obj.species}` };
            });
            return [...acc, ...newTaxaNames];
        }, [] as FilterListItem[]);

        // Species with genera keyed by name, for map dropdown
        const speciesWithGeneraKeyByName = Object.values(data.speciesByGenera).reduce(
            (acc, curr) => {
                // Make sure that taxa are unique
                const uniqTaxa = uniqBy(curr, "species");
                const newTaxaNames = uniqTaxa.map(obj => {
                    const taxon = `${obj.genus} ${obj.species}`;
                    return { key: taxon, label: taxon };
                });
                return [...acc, ...newTaxaNames];
            },
            [] as FilterListItem[]
        );

        dispatch(setSpeciesFilterList(data));
        dispatch(setSpeciesWithGeneraKeyByName(speciesWithGeneraKeyByName));
        dispatch(setSpeciesWithGenera(speciesWithGenera));
    };
}

export function loadProjectsData(): AppThunk {
    return async dispatch => {
        try {
            const response = await api("projects-filter-list");
            const data: ProjectsFilterList = await response.json();
            dispatch(setProjectFilterList(data));
        } catch (e) {
            console.log("Error loading projects data", e);
            dispatch(setProjectFilterList({ projects: [] }));
        }
    };
}

export function loadCollectorsData(): AppThunk {
    return async dispatch => {
        try {
            const response = await api("collectors-filter-list");
            const data: CollectorsList = await response.json();
            const collectorsFilterList: FilterListItem[] = data.map(c => {
                return { key: c, label: c };
            });

            dispatch(constantDataSlice.actions.setCollectorList(collectorsFilterList));
        } catch (e) {
            console.log("Error loading collectors data", e);
            dispatch(constantDataSlice.actions.setCollectorList([]));
        }
    };
}

export function setActiveGenus(newGenus: string): AppThunk {
    return async dispatch => {
        // Update URL, removing species
        // As it's likely the species won't match the new genus
        // Also reset the page number
        const urlParams = qs.parse(history.location.search.slice(1));
        const { species, page, genus, ...rest } = urlParams;
        history.push({
            search: qs.stringify({ ...rest, page: 1, ...(newGenus && { genus: newGenus }) }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveGenus(newGenus));
        dispatch(constantDataSlice.actions.setActiveSpecies(""));
    };
}

export function setActiveSpecies(newSpecies: string): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, species, ...rest } = urlParams;
        history.push({
            search: qs.stringify({ ...rest, page: 1, ...(newSpecies && { species: newSpecies }) }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveSpecies(newSpecies));
    };
}

export function setActiveCollector(newCollector: string): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        const { collector, ...rest } = urlParams;
        history.push({
            search: qs.stringify({ ...rest, collector: newCollector }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveCollector(newCollector));
    };
}

export function setActiveGenusAndSpecies(newGenus: string, newSpecies: string): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, species, genus, ...rest } = urlParams;
        history.push({
            search: qs.stringify({
                ...rest,
                page: 1,
                ...(newGenus && { genus: newGenus }),
                ...(newSpecies && { species: newSpecies }),
            }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveGenus(newGenus));
        dispatch(constantDataSlice.actions.setActiveSpecies(newSpecies));
    };
}

/* For making sure if there is a selected genus query, the associated
   species list is calculated on page load  */
export function setActiveGenusAndSpeciesFromUrl(): AppThunk {
    return async dispatch => {
        const { species, genus } = qs.parse(history.location.search.slice(1));

        // Update redux
        if (genus) dispatch(constantDataSlice.actions.setActiveGenus(genus as string));
        if (species) dispatch(constantDataSlice.actions.setActiveSpecies(species as string));
    };
}

export function setActiveType(type: SampleListItemType): AppThunk {
    return async dispatch => {
        // Update URL, reset page number
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, ...rest } = urlParams;

        history.push({ search: qs.stringify({ ...rest, type: type, ...(page && { page: 1 }) }) });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveType(type));
    };
}

export function setActiveTaxonType(taxonType: TaxonType): AppThunk {
    return async dispatch => {
        // Update URL, reset page number
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, ...rest } = urlParams;

        history.push({
            search: qs.stringify({
                ...rest,
                taxonType: taxonType,
                ...(page && { page: 1 }),
            }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveTaxonType(taxonType));
    };
}
export function setActiveTissueSampleType(
    tissueSampleType: TissueSampleType | undefined
): AppThunk {
    return async dispatch => {
        // Update URL, reset page number
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, ...rest } = urlParams;

        history.push({
            search: qs.stringify({
                ...rest,
                tissueSampleType: tissueSampleType,
                ...(page && { page: 1 }),
            }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveTissueSampleType(tissueSampleType));
    };
}

export function setActiveProvisionalStatus(
    provisionalStatus: ProvisionalAcceptedStatus | undefined
): AppThunk {
    return async dispatch => {
        // Update URL, reset page number
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, ...rest } = urlParams;

        history.push({
            search: qs.stringify({
                ...rest,
                provisionalStatus: provisionalStatus,
                ...(page && { page: 1 }),
            }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveProvisionalStatus(provisionalStatus));
    };
}

export function setActiveArchivedStatus(archivedStatus: TrueOrFalseType): AppThunk {
    return async dispatch => {
        // Update URL, reset page number
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, ...rest } = urlParams;

        history.push({
            search: qs.stringify({
                ...rest,
                archivedStatus: archivedStatus,
                ...(page && { page: 1 }),
            }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveArchivedStatus(archivedStatus));
    };
}

// This is for the checkboxes on the map. The URL becomes a comma-separated list
// e.g. "Tissue (Sent to DArT),Incidental Observation" that is parsed on the BE.
export function setActiveMapTypes(types: MapItemType[]): AppThunk {
    const urlTypes = types.join(",");
    return async dispatch => {
        // Update URL, reset page number
        const urlParams = qs.parse(history.location.search.slice(1));
        history.push({ search: qs.stringify({ ...urlParams, type: urlTypes }) });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveMapTypes(types));
    };
}

export function setActiveProject(newProject: string): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        const { page, project, ...rest } = urlParams;
        history.push({
            search: qs.stringify({ ...rest, page: 1, ...(newProject && { project: newProject }) }),
        });

        // Update redux
        dispatch(constantDataSlice.actions.setActiveProject(newProject));
    };
}

export function setPaginationPage(page: number): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        history.push({ search: qs.stringify({ ...urlParams, page: page }) });

        // Update redux
        dispatch(constantDataSlice.actions.setPaginationPage(page));
    };
}

export function setSortingBy(newSortBy: ColumnKey, newSortDirection: SortDirection): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        const { sortBy, sortDirection, ...rest } = urlParams;
        history.push({
            search: qs.stringify({ ...rest, sortBy: newSortBy, sortDirection: newSortDirection }),
        });

        // Update redux
        dispatch(
            constantDataSlice.actions.setSortingBy({
                sortBy: newSortBy,
                sortDirection: newSortDirection,
            })
        );
    };
}

export function setQuery(query: string): AppThunk {
    return async dispatch => {
        // Update URL
        const urlParams = qs.parse(history.location.search.slice(1));
        if (urlParams.page) delete urlParams.page; // Reset page
        history.push({ search: qs.stringify({ ...urlParams, query: query }) });

        // Update redux
        dispatch(constantDataSlice.actions.setQuery(query));
    };
}

export function clearConstantData(): AppThunk {
    return async dispatch => {
        // Update redux
        dispatch(constantDataSlice.actions.setActiveGenus(""));
        dispatch(constantDataSlice.actions.setActiveSpecies(""));
        dispatch(constantDataSlice.actions.setActiveProject(""));
        dispatch(constantDataSlice.actions.setActiveType(undefined));
        dispatch(constantDataSlice.actions.setActiveTaxonType(undefined));
        dispatch(constantDataSlice.actions.setActiveProvisionalStatus(undefined));
        dispatch(constantDataSlice.actions.setActiveTissueSampleType(undefined));
        dispatch(constantDataSlice.actions.setPaginationPage(1));
    };
}
