import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { useSelector } from "../../ducks/root-reducer";
import {
    SampleListQuery,
    selectSamplePagination,
    selectSampleResults,
    selectSamplesIsLoading,
    searchAssets,
} from "../../ducks/samples";
import { ColumnKey } from "../../components/info-table/info-table";
import InfoTable from "../../components/info-table/info-table";
import { parseUrlParams, stringifyUrlParams } from "../../modules/query-helpers";
import LoadingOverlay from "../../components/loading-overlay/loading-overlay";
import { setQuery, SortDirection } from "../../ducks/constant-data";
import TableHeader from "../../components/table-header/table-header";
import SearchBar from "../../components/search-bar/search-bar";
import { BASE_API_URL } from "../../modules/api";
import AddNewModal from "../../components/add-new-modal/add-new-modal";
import NewTissueSampleForm from "../../components/add-new-modal/new-tissue-sample-form";
import NewVoucherSampleForm from "../../components/add-new-modal/new-voucher-sample-form";
import NewIncidentalObservationForm from "../../components/add-new-modal/new-incidental-observation-form";
import { SampleListItemType, sortableColumns } from "../../types";
import { sampleColumns } from "./samples-page-config";

function SamplesPage() {
    const location = useLocation();
    const dispatch = useDispatch();

    const sampleResults = useSelector(selectSampleResults);
    const pagination = useSelector(selectSamplePagination);
    const isLoading = useSelector(selectSamplesIsLoading);
    const [newModalOpen, setNewModalOpen] = useState<boolean>(false);
    const [newItemType, setNewItemType] = useState<SampleListItemType>(SampleListItemType.Tissue);

    const sampleListQuery: SampleListQuery = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    useEffect(() => {
        dispatch(searchAssets(sampleListQuery));
    }, [dispatch, sampleListQuery]);

    const downloadLink: string = useMemo(() => {
        const { page, ...paramsWithoutPage } = sampleListQuery;
        const stringParams = paramsWithoutPage ? `${stringifyUrlParams(paramsWithoutPage)}` : "";

        return `${BASE_API_URL}/samples.csv?${stringParams}`;
    }, [sampleListQuery]);

    const renderModalForm = (newItemType: SampleListItemType): JSX.Element => {
        const handleClose = () => {
            setNewModalOpen(false);
        };
        switch (newItemType) {
            case SampleListItemType.Tissue:
                return <NewTissueSampleForm handleClose={handleClose} />;
            case SampleListItemType.Voucher:
                return <NewVoucherSampleForm handleClose={handleClose} />;
            case SampleListItemType.IncidentalObservation:
                return <NewIncidentalObservationForm handleClose={handleClose} />;
            default:
                return <div />;
        }
    };

    const { sortBy, sortDirection, query } = sampleListQuery;

    return (
        <div style={{ minHeight: 500, position: "relative", height: "100%", width: "100%" }}>
            {isLoading && <LoadingOverlay />}
            <TableHeader
                title="Samples"
                downloadOptions={[
                    {
                        label: "CSV",
                        downloadHref: downloadLink,
                    },
                ]}
                createNewOptions={[
                    {
                        label: "Tissue",
                        onClick: () => {
                            setNewItemType(SampleListItemType.Tissue);
                            setNewModalOpen(true);
                        },
                    },
                    {
                        label: "Voucher",
                        onClick: () => {
                            setNewItemType(SampleListItemType.Voucher);
                            setNewModalOpen(true);
                        },
                    },
                    {
                        label: "Observation",
                        onClick: () => {
                            setNewItemType(SampleListItemType.IncidentalObservation);
                            setNewModalOpen(true);
                        },
                    },
                ]}
            />
            <SearchBar
                placeHolder={"NSW number(s)"}
                query={query}
                onKeyUp={newQuery => {
                    dispatch(setQuery(newQuery));
                }}
            />
            {sampleResults && (
                <InfoTable
                    rows={sampleResults}
                    columns={sampleColumns}
                    sortableColumns={sortableColumns.samples}
                    pagination={pagination}
                    sortBy={sortBy as ColumnKey}
                    sortDirection={sortDirection as SortDirection}
                    backToPage={"samples"}
                    dataCy="samples-table"
                />
            )}
            <AddNewModal
                isOpen={newModalOpen}
                handleClose={() => {
                    setNewModalOpen(false);
                }}
                body={renderModalForm(newItemType)}
            />
        </div>
    );
}

export default SamplesPage;
