import React from "react";
import DetailPageFilter from "../detail-page-filter/detail-page-filter";
import DetailFormTextfield from "../detail-page-textfield/detail-page-textfield";
import DetailFormCheckboxes from "../detail-page-checkboxes/details-page-checkboxes";
import DetailPageDatePicker from "../detail-page-datepicker/detail-page-datepicker";
import DetailPageAutocomplete from "../detail-page-autocomplete/detail-page-autocomplete";
import CoordinatesField from "../coordinates-field/coordinates-field";
import DetailPageLink from "../detail-page-link/detail-page-link";
import { FilterOptionsFromState, DataError, DetailKey, DetailPageField } from "../../types";
import { selectSpeciesWithGenera, selectProjectData } from "../../ducks/constant-data";
import { useSelector } from "../../ducks/root-reducer";
import { FilterListItem } from "../filter-dropdown/filter-dropdown";
import { Point } from "geojson";
import { AppPage } from "../../ducks/ui";
import { SetPatchFunction } from "../../hooks/useDetailForm";
import AutocompleteSelectAsync from "../detail-page-autocomplete-async/autocomplete-select-async";

export type UpdateOptions = {
    appendString?: boolean;
    useIndex?: number;
};

type DetailFormFieldProps = {
    id: string; // id of entity, e.g. NSWnumber
    onUpdateField: SetPatchFunction;
    field: DetailPageField;
    backToPage: AppPage;
    detailObject: any;
    parentKey?: DetailKey;
    validationCheck?: DataError | undefined;
};

function DetailFormField({
    onUpdateField,
    field,
    detailObject,
    parentKey,
    backToPage,
    validationCheck,
    id,
}: DetailFormFieldProps) {
    const speciesWithGenera = useSelector(selectSpeciesWithGenera);
    const projects = useSelector(selectProjectData) as FilterListItem[];

    const selectStateOptions = (
        optionsKey: FilterOptionsFromState | undefined
    ): FilterListItem[] => {
        switch (optionsKey) {
            case "speciesWithGenera":
                return speciesWithGenera ? speciesWithGenera : [];
            case "projectList":
                return projects ? projects : [];
            default:
                return [];
        }
    };

    // Get value to display
    const rawValue = detailObject ? detailObject[field.key] : "";
    const isReadOnly =
        typeof field.isReadOnly === "function" ? field.isReadOnly(detailObject) : field.isReadOnly;
    const value =
        isReadOnly && detailObject && rawValue === undefined
            ? "-"
            : rawValue !== undefined
            ? rawValue
            : "";

    const label = (field.getLabel ? field.getLabel(detailObject) : field.label) || "";
    return (
        <div>
            {field.inputType === "textfield" && (
                <DetailFormTextfield
                    label={label}
                    value={value || ""}
                    isReadOnly={isReadOnly}
                    error={validationCheck}
                    handleChange={e => {
                        onUpdateField(field.key, e.target.value, id, parentKey);
                    }}
                    formatReadOnlyValue={field.formatReadOnlyValue}
                />
            )}
            {(field.options || field.optionsKey) && field.inputType === "filter" && (
                <DetailPageFilter
                    handleChange={e => {
                        onUpdateField(field.key, e.target.value, id, parentKey);
                    }}
                    label={label}
                    isReadOnly={isReadOnly}
                    value={value}
                    options={field.options || selectStateOptions(field.optionsKey)}
                />
            )}
            {(field.options || field.optionsKey) && field.inputType === "autocomplete" && (
                <DetailPageAutocomplete
                    handleChange={e => {
                        onUpdateField(field.key, e.target.value, id, parentKey);
                    }}
                    label={label}
                    isReadOnly={isReadOnly}
                    value={value}
                    options={field.options || selectStateOptions(field.optionsKey)}
                    addEmptyOption={false}
                />
            )}

            {field.options && field.inputType === "checkboxes" && (
                <DetailFormCheckboxes
                    handleChange={e => {
                        onUpdateField(field.key, e.target.value, id, parentKey);
                    }}
                    label={label}
                    isReadOnly={isReadOnly}
                    options={field.options}
                    error={false}
                    errorText={"Hej"}
                    checkedValues={value as string}
                />
            )}
            {field.inputType === "datepicker" && (
                <DetailPageDatePicker
                    label={label}
                    isReadOnly={isReadOnly}
                    value={value}
                    handleChange={newDate => {
                        onUpdateField(field.key, newDate?.toDate(), id, parentKey);
                    }}
                />
            )}
            {field.inputType === "coordinates" && (
                <CoordinatesField
                    isReadOnly={isReadOnly}
                    value={value as Point}
                    error={validationCheck}
                    handleChange={newLatLong => {
                        onUpdateField(field.key, newLatLong, id, parentKey);
                    }}
                />
            )}
            {field.inputType === "link" && (
                <DetailPageLink
                    label={label}
                    linkText={value}
                    backToPage={backToPage}
                    href={field.link?.externalLink ? value : field.link?.internalLinkPath + value}
                    external={field.link?.externalLink}
                />
            )}
            {field.inputType === "autocomplete-async" && (
                <AutocompleteSelectAsync
                    handleChange={e => {
                        onUpdateField(field.key, e.target.value, id, parentKey);
                    }}
                    getOptions={field.getOptions}
                    label={label}
                    value={value}
                />
            )}
        </div>
    );
}

export default DetailFormField;
