import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { SamplingEventDetailLinkedSample, SamplingEventDetailLinkedSpecies } from "../../types";
import Typography from "@material-ui/core/Typography";
import { AppPage } from "../../ducks/ui";
import AppLink from "../../components/app-link/app-link";

type EventDetailSamplesProps = {
    species: SamplingEventDetailLinkedSpecies[];
    backToPage: AppPage;
};

function EventDetailSamplesList({ species, backToPage }: EventDetailSamplesProps) {
    const getSampleTitleText = (sample: SamplingEventDetailLinkedSample) => {
        return `${sample.id}${sample.archived ? " [ARCHIVED]" : ""}`;
    };

    return (
        <div style={{ margin: "30px 10px" }}>
            <h3>All samples</h3>
            {species.map((species, i) => {
                const { acceptedName, samples, taxonKey } = species;
                return (
                    <div key={i} style={{ width: 450 }}>
                        <Typography
                            component="p"
                            variant="body1"
                            style={{ marginBottom: 10, marginTop: 20 }}
                            noWrap
                        >
                            <AppLink
                                to={`/dashboard/species/${taxonKey}/`}
                                innerText={acceptedName}
                                backToPage={backToPage}
                            />
                            {` (${samples.length})`}
                        </Typography>

                        <Table>
                            <TableBody>
                                {samples.map((sample, j) => {
                                    return (
                                        <TableRow
                                            key={sample.id}
                                            style={{
                                                backgroundColor: j % 2 === 0 ? "#F9F9F9" : "white",
                                            }}
                                        >
                                            <TableCell>
                                                <AppLink
                                                    to={`/dashboard/samples/${sample.type.toLocaleLowerCase()}/${
                                                        sample.id
                                                    }`}
                                                    innerText={getSampleTitleText(sample)}
                                                    backToPage={backToPage}
                                                />
                                            </TableCell>
                                            <TableCell>{sample.type}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                );
            })}
        </div>
    );
}

export default EventDetailSamplesList;
