import {
    samplingStatuses,
    taxonPriorities,
    editableProvisionalTypes,
    infraSpecificRank,
} from "../../modules/listItemsFromEnums";
import { semicolonStringToCommaString } from "../../modules/formatters";
import { ProvisionalAcceptedStatus, TaxonType, DetailPageSection } from "../../types";
import { checkNameIsUnique } from "../../modules/validation/checkValidId";

export const speciesForm: DetailPageSection[] = [
    {
        sectionTitle: "Taxonomy",
        sectionNotes:
            'Note that any changes to provisional species (e.g. name, taxonomic units, archive) can only be made to those with a Provisional Accepted Status of "Not accepted".',
        fields: [
            {
                // Accepted name is editable (and validated) only if of "not_accepted" ProvisionalAcceptedStatus
                label: "Accepted name",
                key: "acceptedName",
                isReadOnly: false,
                inputType: "textfield",
                validate: (name, id) => checkNameIsUnique("taxon-name-is-unique", name, id),
                showCondition: e =>
                    e.provisionalAcceptedStatus === ProvisionalAcceptedStatus.NOT_ACCEPTED,
            },
            {
                label: "Accepted name",
                key: "acceptedName",
                isReadOnly: true,
                inputType: "textfield",
                showCondition: e =>
                    e.provisionalAcceptedStatus !== ProvisionalAcceptedStatus.NOT_ACCEPTED,
            },
            {
                label: "Taxon type",
                key: "type",
                isReadOnly: true,
                inputType: "textfield",
            },
            {
                label: "Provisional Accepted Status",
                key: "provisionalAcceptedStatus",
                isReadOnly: false,
                options: editableProvisionalTypes,
                inputType: "filter",
                showCondition: e => e.type === TaxonType.PROVISIONAL,
            },
            {
                label: "Synonyms",
                key: "synonymy",
                isReadOnly: true,
                inputType: "textfield",
                formatReadOnlyValue: semicolonStringToCommaString,
            },
            {
                label: "Genus",
                key: "genus",
                isReadOnly: e =>
                    e.provisionalAcceptedStatus !== ProvisionalAcceptedStatus.NOT_ACCEPTED,
                inputType: "textfield",
                showCondition: e => e.type === TaxonType.PROVISIONAL,
            },
            {
                label: "Species",
                key: "species",
                isReadOnly: e =>
                    e.provisionalAcceptedStatus !== ProvisionalAcceptedStatus.NOT_ACCEPTED,
                inputType: "textfield",
                showCondition: e => e.type === TaxonType.PROVISIONAL,
            },
            {
                label: "Infraspecific Rank",
                key: "infraSpecificRank",
                isReadOnly: true,
                inputType: "textfield",
                showCondition: e =>
                    e.provisionalAcceptedStatus === ProvisionalAcceptedStatus.ACCEPTED,
            },
            {
                label: "Infraspecific Rank",
                key: "infraSpecificRank",
                isReadOnly: false,
                options: infraSpecificRank,
                inputType: "filter",
                showCondition: e =>
                    e.provisionalAcceptedStatus === ProvisionalAcceptedStatus.NOT_ACCEPTED,
            },
            {
                label: "Infraspecific Epithet",
                key: "infraSpecificEpithet",
                isReadOnly: e =>
                    e.provisionalAcceptedStatus !== ProvisionalAcceptedStatus.NOT_ACCEPTED,
                inputType: "textfield",
                showCondition: e => e.type === TaxonType.PROVISIONAL,
            },
            {
                label: "PlantNet URL",
                key: "plantNETurl",
                isReadOnly: true,
                link: { externalLink: true },
                inputType: "link",
                showCondition: e => e.type === TaxonType.ALA,
            },
        ],
    },
    {
        sectionTitle: "Priority",
        fields: [
            {
                label: "Priority",
                key: "priority",
                isReadOnly: false,
                options: taxonPriorities,
                inputType: "filter",
            },
            {
                label: "Notes",
                key: "notes",
                isReadOnly: false,
                inputType: "textfield",
            },
        ],
    },
    {
        sectionTitle: "Sampling status",
        fields: [
            {
                key: "samplingStatus",
                label: "Sampling status",
                isReadOnly: false,
                inputType: "",
                subFields: [
                    {
                        label: "ACT",
                        key: "ACT",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "NSW",
                        key: "NSW",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "NT",
                        key: "NT",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "Qld",
                        key: "Qld",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "SA",
                        key: "SA",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "Tas",
                        key: "Tas",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "Vic",
                        key: "Vic",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    {
                        label: "WA",
                        key: "WA",
                        isReadOnly: false,
                        options: samplingStatuses,
                        inputType: "filter",
                    },
                    { label: "Notes", key: "notes", isReadOnly: false, inputType: "textfield" },
                ],
            },
        ],
    },
];
