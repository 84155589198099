import React, { useMemo, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ListItemIcon,
    ExpansionPanelDetails,
} from "../../style/expansion-panel.styles";
import SpeciesMenu from "./species-menu";
import SamplesMenu from "./samples-menu";
import MapMenu from "./map-menu";
import OrdersMenu from "./orders-menu";
import { ChevronLeft } from "@material-ui/icons";
import { LeafIcon, StackIcon, FlowerIcon, MapIcon, DnaIcon, ImportIcon } from "../icons";
import { useDispatch } from "react-redux";
import { capitalize } from "../../modules/formatters";
import { useLocation } from "react-router-dom";
import { useSelector } from "../../ducks/root-reducer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
    setActiveGenus,
    setActiveSpecies,
    setActiveGenusAndSpecies,
    selectSpeciesDataForDropdown,
    selectGenusDataForDropdown,
    selectSpeciesWithGeneraKeyByName,
    selectProjectData,
    setActiveProject,
    selectCollectorsData,
    setActiveType,
    setActiveTaxonType,
    setActiveProvisionalStatus,
    setActiveTissueSampleType,
    setActiveCollector,
    setActiveMapTypes,
    clearConstantData,
    setActiveArchivedStatus,
    setActiveGenusAndSpeciesFromUrl,
} from "../../ducks/constant-data";
import { AppPage, setActivePage, selectDrawerIsOpen } from "../../ducks/ui";
import { MapItemType } from "../../types";
import { parseUrlParams } from "../../modules/query-helpers";
import { history } from "../../pages/root";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(_ => ({
    expansionPanelSummaryContent: {
        margin: 0,
        height: 68,
        "&.expanded": {
            margin: 0,
        },
    },
}));

type AppPanel = { name: AppPage; icon: JSX.Element };

const iconStyle = { width: 20, height: 20, color: "inherit" };
const panels: AppPanel[] = [
    { name: "samples", icon: <LeafIcon style={iconStyle} /> },
    { name: "map", icon: <MapIcon style={iconStyle} /> },
    { name: "events", icon: <StackIcon style={iconStyle} /> },
    { name: "species", icon: <FlowerIcon style={iconStyle} /> },
    { name: "orders", icon: <DnaIcon style={iconStyle} /> },
    { name: "import", icon: <ImportIcon style={iconStyle} /> },
];

function MainMenu() {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();

    // The label for where to 'go back to' in the detail panel
    const hist = useHistory();
    const { location: loc } = hist;
    const backTo = loc.state as string;

    // UI
    const drawerIsOpen = useSelector(selectDrawerIsOpen);

    // Routing
    const location = useLocation();
    const currentTab: string = location.pathname.split("/")[2] || "samples";
    // If there's nothing after species/, samples/ etc in the URL, it's not a detail page
    const detailParam: string | undefined = location.pathname.split("/")[3];
    const isDetailPage: boolean = detailParam !== undefined && detailParam !== "";
    const query = useMemo(() => {
        return parseUrlParams(location.search.slice(1));
    }, [location.search]);

    // Constant data
    const genera = useSelector(selectGenusDataForDropdown);
    const species = useSelector(selectSpeciesDataForDropdown);
    const speciesWithGenera = useSelector(selectSpeciesWithGeneraKeyByName);
    const projects = useSelector(selectProjectData);
    const collectors = useSelector(selectCollectorsData);

    // Filter params
    const activeType = query.type as string;
    const activeTissueSampleType = query.tissueSampleType as string;
    const activeProject = query.project as string;
    const activeGenus = query.genus as string;
    const activeSpecies = query.species as string;
    const activeCollector = query.collector as string;
    const activeTaxonType = query.taxonType as string;
    const activeProvisionalStatus = query.provisionalStatus as string;
    const activeArchivedStatus = query.archivedStatus as string;

    // Make sure we're setting activeGenus on page load here, so that species filter
    // list for the chosen genus is calculated on initial page load.
    useEffect(() => {
        if (activeGenus) dispatch(setActiveGenusAndSpeciesFromUrl());
    }, [activeGenus, dispatch]);

    const handleGenusChange = (event: any) => {
        dispatch(setActiveGenus(event.target.value));
    };
    const handleSpeciesChange = (event: any) => {
        dispatch(setActiveSpecies(event.target.value));
    };
    const handleProjectChange = (event: any) => {
        dispatch(setActiveProject(event.target.value));
    };
    const handleTypeChange = (event: any) => {
        dispatch(setActiveType(event.target.value));
        dispatch(setActiveTissueSampleType(undefined));
    };
    const handleTissueSampleTypeChange = (event: any) => {
        dispatch(setActiveTissueSampleType(event.target.value));
    };
    const handleCollectorChange = (event: any) => {
        dispatch(setActiveCollector(event.target.value));
    };
    const handleMapTypeChange = (types: MapItemType[]): void => {
        dispatch(setActiveMapTypes(types));
    };
    const handleTaxonChange = (event: any) => {
        const taxonElems = event.target.value.split(" ");
        const genus = taxonElems[0];
        const species = taxonElems[1];
        // If there's no genus and no species, we can't do the search
        // As the map can't handle that many results
        if (!genus && !species) return;
        dispatch(setActiveGenusAndSpecies(genus, species));
    };
    const handleTaxonTypeChange = (event: any): void => {
        dispatch(setActiveProvisionalStatus(undefined));
        dispatch(setActiveTaxonType(event.target.value));
    };
    const handleProvisionalStatusChange = (event: any): void => {
        dispatch(setActiveProvisionalStatus(event.target.value));
    };
    const handleArchivedStatusChange = (event: any): void => {
        dispatch(setActiveArchivedStatus(event.target.value));
    };

    return (
        <>
            {isDetailPage ? (
                // "Back to X" button
                <ListItem
                    style={{ marginTop: 20 }}
                    onClick={() => {
                        history.goBack();
                    }}
                    button
                >
                    <ListItemIcon style={{ paddingLeft: drawerIsOpen ? 0 : 10 }}>
                        <ChevronLeft />
                    </ListItemIcon>
                    <ListItemText primary={capitalize(backTo || "")} />
                </ListItem>
            ) : (
                <div>
                    {panels.map((panel, i) => {
                        const index = i + 1;
                        return (
                            <ExpansionPanel
                                square
                                expanded={currentTab === panel.name && drawerIsOpen}
                                key={i}
                                style={{ borderTopWidth: 0 }}
                            >
                                <ExpansionPanelSummary
                                    aria-controls={`panel${index}d-content`}
                                    id={`panel${index}d-header`}
                                    classes={{
                                        content: classes.expansionPanelSummaryContent,
                                        expanded: "expanded",
                                    }}
                                    style={{
                                        backgroundColor:
                                            currentTab === panel.name
                                                ? theme.palette.secondary.main
                                                : theme.palette.neutral.main,
                                        color:
                                            currentTab === panel.name
                                                ? "white"
                                                : theme.palette.text.primary,
                                        borderStyle: "none",
                                        borderBottom: "1px solid white",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                    onClick={() => {
                                        dispatch(clearConstantData());
                                        dispatch(setActivePage(panel.name));
                                    }}
                                >
                                    <ListItem button>
                                        <ListItemIcon
                                            style={{ paddingLeft: drawerIsOpen ? 10 : 18 }}
                                        >
                                            <div
                                                style={{
                                                    color:
                                                        currentTab === panel.name
                                                            ? "white"
                                                            : theme.palette.text.primary,
                                                }}
                                            >
                                                {panel.icon}
                                            </div>
                                        </ListItemIcon>
                                        {drawerIsOpen && (
                                            <ListItemText primary={`${capitalize(panel.name)}`} />
                                        )}
                                    </ListItem>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails
                                    style={{
                                        padding:
                                            panel.name === "events" || panel.name === "import"
                                                ? 0
                                                : 16,
                                    }}
                                >
                                    {panel.name === "samples" && (
                                        <SamplesMenu
                                            genera={genera}
                                            species={species}
                                            projects={projects}
                                            activeGenus={activeGenus}
                                            activeSpecies={activeSpecies}
                                            activeProject={activeProject}
                                            activeType={activeType}
                                            activeTaxonType={activeTaxonType}
                                            activeProvisionalStatus={activeProvisionalStatus}
                                            activeTissueSampleType={activeTissueSampleType}
                                            handleGenusChange={handleGenusChange}
                                            handleSpeciesChange={handleSpeciesChange}
                                            handleProjectChange={handleProjectChange}
                                            handleTypeChange={handleTypeChange}
                                            handleTaxonTypeChange={handleTaxonTypeChange}
                                            handleProvisionalStatusChange={
                                                handleProvisionalStatusChange
                                            }
                                            handleTissueSampleTypeChange={
                                                handleTissueSampleTypeChange
                                            }
                                        />
                                    )}
                                    {panel.name === "map" && (
                                        <MapMenu
                                            speciesWithGenera={speciesWithGenera}
                                            projects={projects}
                                            collectors={collectors}
                                            activeGenus={activeGenus}
                                            activeSpecies={activeSpecies}
                                            activeProject={activeProject}
                                            activeCollector={activeCollector}
                                            activeType={activeType}
                                            handleTaxonChange={handleTaxonChange}
                                            handleProjectChange={handleProjectChange}
                                            handleMapTypeChange={handleMapTypeChange}
                                            handleCollectorChange={handleCollectorChange}
                                        />
                                    )}
                                    {panel.name === "species" && (
                                        <SpeciesMenu
                                            genera={genera}
                                            species={species}
                                            activeGenus={activeGenus}
                                            activeSpecies={activeSpecies}
                                            activeTaxonType={activeTaxonType}
                                            activeProvisionalStatus={activeProvisionalStatus}
                                            activeArchivedStatus={activeArchivedStatus}
                                            handleGenusChange={handleGenusChange}
                                            handleSpeciesChange={handleSpeciesChange}
                                            handleTaxonTypeChange={handleTaxonTypeChange}
                                            handleProvisionalStatusChange={
                                                handleProvisionalStatusChange
                                            }
                                            handleArchivedStatusChange={handleArchivedStatusChange}
                                        />
                                    )}
                                    {panel.name === "orders" && (
                                        <OrdersMenu
                                            genera={genera}
                                            species={species}
                                            projects={projects}
                                            activeGenus={activeGenus}
                                            activeSpecies={activeSpecies}
                                            activeProject={activeProject}
                                            handleGenusChange={handleGenusChange}
                                            handleSpeciesChange={handleSpeciesChange}
                                            handleProjectChange={handleProjectChange}
                                        />
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        );
                    })}
                </div>
            )}
        </>
    );
}

export default MainMenu;
